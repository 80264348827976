



































































































import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import LoaderLogo from '@/components/LoaderLogo.vue';
import SearchResults from '@/components/SearchResults.vue';
import TextInput from '@/components/TextInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import GraphApi from '@/api/graphApi';

@Component({
    components: {
        LoaderLogo,
        TextInput,
        SearchResults,
        BaseButton,
    },
})
export default class EditD365Sidebar extends Vue {
    // #region props

    public isDirty = false;

    public error = { message: '' };

    public d365Name = '';

    public statusValid = false;

    public d365SearchResultsAvailable: any[] | null = null;

    public d365SearchResultsNotAvailable: any[] | null = null;

    public loadingResults = false;

    public resultsNotSearched = false;

    D365UpdateInProcess = false;

    currentD365: any = null;

    showPopup = false;

    @Prop() orgId!: string;

    @Prop() orgName!: string;

    @Prop({ default: '' }) valueOverride!: string;

    get resultsFound (): boolean | null {
        return this.d365SearchResultsAvailable && this.d365SearchResultsNotAvailable && (this.d365SearchResultsAvailable?.length + this.d365SearchResultsNotAvailable?.length) > 0;
    }
    // #endregion

    d365TypeText (d365Type: number) { // would be nice to show the difference for ones that have already been added to Authorise
        switch (d365Type) {
            case 1: {
                return 'D365 Account';
            }
            case 2: {
                return 'D365 Scheme';
            }
            default: {
                return 'No associations';
            }
        }
    }

    created () {
        this.d365Name = this.valueOverride;
    }

    public async updateToNewD365 (d365: any): Promise<void> {
        if (d365.orgId === null) {
            this.D365UpdateInProcess = true;
            await GraphApi.updateD365ForOrg({
                OrgId: this.orgId,
                D365Id: d365.d365Id,
            });
            this.$emit('requestLatestData');
            this.D365UpdateInProcess = false;
            this.$router.push(`/org/${this.orgId}`);
        } else {
            this.$router.push(`/org/${this.orgId}`);
        }
    }

    showPopupToConfirm (d365: any) {
        this.currentD365 = d365;
        this.showPopup = true;
    }

    confirmD365Change () {
        this.showPopup = false;
        this.updateToNewD365(this.currentD365);
    }

    cancelD365Change () {
        this.showPopup = false;
    }

    async checkForMatchingD365 (): Promise<void> {
        this.d365SearchResultsAvailable = null;
        this.d365SearchResultsNotAvailable = null;
        if (this.d365Name.length >= 3) {
            this.loadingResults = true;
            const results: any = await GraphApi.getD365ByName({
                Name: this.d365Name,
            });
            if (results.data.length > 0) {
                this.d365SearchResultsAvailable = results.data.filter((d365: any) => d365.orgId === null);
                this.d365SearchResultsNotAvailable = results.data.filter((d365: any) => d365.orgId !== null);
                this.resultsNotSearched = false;
            } else {
                this.resultsNotSearched = true;
            }
            this.loadingResults = false;
        }
    }

    clearSearch () {
        this.d365SearchResultsAvailable = null;
        this.d365SearchResultsNotAvailable = null;
        this.d365Name = '';
        this.resultsNotSearched = false;
    }

    public forceClose (): void {
        this.$store.commit('toggleSideBar', false);
        this.$store.dispatch('portalInView', '');
    }

    /*     get sortedD365NameSearchResults (): any {
        return this.d365NameSearchResults?.sort((a, b) => {
            if (a.orgId < b.orgId) { return 1; }
            if (a.orgId > b.orgId) { return -1; }
            return 0;
        });
    } */

/*     get firstOfNewTypeIndex (): number | null {
        if (this.d365NameSearchResults) {
            return this.d365NameSearchResults.findIndex((entity: any) => entity.orgId !== null);
        }
        return null;
    } */
}
