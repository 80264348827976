var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "top" }, [
        _c("div", { staticClass: "left" }, [
          _c(
            "div",
            { staticClass: "card-body--header" },
            [
              _c("router-link", {
                attrs: { to: "/org/" + _vm.org.org.id, custom: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var navigate = ref.navigate
                      return [
                        _c("h3", { on: { click: navigate } }, [
                          _vm._v(_vm._s(_vm.org.org.name)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c("ApplicationBubbleWrapper", {
              attrs: { applicationList: _vm.org.apps, chip: "" },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }