var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "description" }, [
      _c("h2", { staticClass: "description-heading" }, [
        _vm._v("What is LCP Authorise?"),
      ]),
      _c("div", { staticClass: "description-text" }, [
        _c("p", { staticClass: "description-text--item" }, [
          _vm._v(
            "LCP Authorise has been designed to manage your access to LCP's consulting tools. It links the clients we have set up in Microsoft D365 (such as the ABC pension fund) to a Microsoft teams setup (the space you already use to manage work for that client). We can use that association and the team's members list to manage access to LCP's consulting tools."
          ),
        ]),
      ]),
      _c("img", {
        staticClass: "description-img",
        attrs: { src: "/imgs/scheme.png", alt: "scheme image" },
      }),
      _c("div", { staticClass: "description-text" }, [
        _c("p", { staticClass: "description-text--item" }, [
          _vm._v(
            "If you don't see your client in the list opposite you can use the "
          ),
          _c("strong", [_vm._v("add new organisation button")]),
          _vm._v(" and search for them in the LCP "),
          _c("strong", [_vm._v("Microsoft Dynamics 365 (D365)")]),
          _vm._v(" database and follow the set by setp guide to add them."),
        ]),
        _c("h3", { staticClass: "description-heading--3" }, [
          _vm._v("I can't find a matching D365 entity!"),
        ]),
        _c("p", { staticClass: "description-text--item" }, [
          _vm._v(
            "You might need to set this up on D365 first. Find out how on the "
          ),
          _c(
            "a",
            {
              attrs: {
                href: "https://lcponline.sharepoint.com/sites/Strategyatlcp/SitePages/D365-Hub.aspx",
                target: "_blank",
              },
            },
            [_vm._v("D365 Hub sharepoint page")]
          ),
          _vm._v("."),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }