



































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import SearchResults from '@/components/SearchResults.vue';
import TextInput from '@/components/TextInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';
// import Org from "@/models/Org";
import GraphApi from '@/api/graphApi';
import Org from '@/models/Org';

@Component({
    components: {
        TextInput,
        SearchResults,
        BaseButton,
    },
})
export default class AddOrgName extends Vue {
    public isDirty = false;

    public error = { message: '' };

    public orgName = '';

    public statusValid = false;

    public orgNameSearchResults: any[] | null = null;

    public loadingResults = false;

    public resultsNotFound = false;

    @Prop() newOrg!: Org;

    @Prop({ default: '' }) valueOverride!: string;

    @Prop() currentStep!: number;

    // #region conditional render computeds
    get searchOrg (): boolean | null | Record<string, unknown> {
        return !this.orgNameSearchResults && !this.orgAlreadyChosen;
    }

    get resultsFound (): boolean | null {
        return this.orgNameSearchResults && this.orgNameSearchResults?.length > 0;
    }

    get orgAlreadyChosen (): boolean {
        return this.newOrg.orgName?.length > 0;
    }

    get firstOfSchemeTypeIndex (): number | null {
        if (this.orgNameSearchResults) {
            return this.orgNameSearchResults.findIndex((org) => org.orgType === 2);
        }
        return null;
    }

    get firstOfAccountTypeIndex (): number | null {
        if (this.orgNameSearchResults) {
            return this.orgNameSearchResults.findIndex((org) => org.orgType === 1);
        }
        return null;
    }

    // #endregion

    orgTypeText (orgType: number) {
        switch (orgType) {
            case 0: {
                return 'LCP Authorise';
            }
            case 1: {
                return 'D365 Account';
            }
            case 2: {
                return 'D365 Scheme';
            }
            default: {
                return 'No associations';
            }
        }
    }

    created () {
        this.orgName = this.valueOverride;
        this.validationCheck();
    }

    routeToOrg (org: any): void {
        if (org.orgType > 0) {
            this.orgName = org.name;
            this.continueWithOrgName();
        } else {
            this.$router.push(`/org/${org.id}`);
        }
    }

    async noResults (): Promise<void> {
        if (await this.checkIfDevUser()) {
            this.addOrganisation();
            return;
        }
        this.resultsNotFound = true;
    }

    async checkForConflicitingOrg (): Promise<void> {
        this.loadingResults = true;
        this.orgNameSearchResults = null;
        const results: any = await GraphApi.getOrgsByName({
            name: this.orgName,
        });
        this.loadingResults = false;

        if (results.data.length === 0) {
            this.noResults();
            return;
        }
        this.orgNameSearchResults = results.data.sort((orgA: any, orgB: any) => orgA.orgType - orgB.orgType);
        this.resultsNotFound = false;

        /* if (this.$route.query.all === 'true') {
            this.orgNameSearchResults = results.data.sort((orgA: any, orgB: any) => orgA.orgType - orgB.orgType);
            this.resultsNotFound = false;
            return;
        }

        const filteredResults = results.data.filter((org: any) => org.orgType !== 1);
        if (filteredResults.length > 0) {
            this.orgNameSearchResults = filteredResults.sort((orgA: any, orgB: any) => orgA.orgType - orgB.orgType);
            this.resultsNotFound = false;
            return;
        }
        this.noResults(); */
    }

    async checkIfDevUser (): Promise<boolean> {
        const user: any = await GraphApi.getUser();
        const isDev: boolean = user.data.department === 'Digital Services – Products';
        return isDev;
        /* return false; */
    }

    continueWithOrgName (): void {
        this.addOrganisation();
    }

    skipStep (): void {
        this.$emit('jumpToStep', 1);
    }

    addOrganisation (): void {
        this.$emit('updateOrg', this.orgName, 'orgName');
        this.$emit('jumpToStep', 1);
        this.validationCheck();
    }

    editSchemeName () {
        this.$emit('updateOrg', '', 'orgName');
        this.$emit('updateValidity', false);
        this.orgName = '';
    }

    validationCheck () {
        if (this.newOrg.orgName === '' || this.newOrg.orgName === undefined) {
            this.$emit('updateValidity', false);
            return;
        }
        this.$emit('updateValidity', true);
    }

    clearSearch () {
        this.orgNameSearchResults = null;
        this.orgName = '';
        this.resultsNotFound = false;
    }
}
