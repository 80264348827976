


























import { Vue, Component, Watch } from 'vue-property-decorator';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import SideBar from '@/components/SideBar.vue';
import SideBarUserSearch from '@/components/SideBarUserSearch.vue';
import Login from '@/views/Login.vue';
import LoaderLogo from '@/components/LoaderLogo.vue';

// import { orgs, users, teamsData, abcUsersAdmin, abcUsers } from "@/data/data";

@Component({
    components: {
        Header, Footer, SideBar, SideBarUserSearch, Login, LoaderLogo,
    },
})
export default class App extends Vue {
    // async created() {
    //   this.$store.commit("updateOrgData", orgs);
    //   this.$store.commit("updateTeams", teamsData);
    //   this.$store.commit("updateUserData", users);
    //   this.$store.commit("updateAbcUsersAdmin", abcUsersAdmin);
    //   this.$store.commit("updateAbcUsers", abcUsers);
    // }

    async mounted () {
        await this.$store
            .dispatch('auth/getToken')
            .then((result) => {
                if (!result) {
                    if (this.$route.query?.signIn || this.$route.query?.signin) {
                        this.$router.push(this.$route.path);
                        this.login();
                    } else {
                        this.$store.commit('setDataLoaded', true);
                    }
                }
            })
            .catch(() => {
                // how do we want to handle this?
                this.$store.commit('setDataLoaded', true);
            });
        if (this.$route.query?.signIn || this.$route.query?.signin) {
            this.$router.push(this.$route.path);
        }
    }

    get dataLoaded (): boolean {
        return this.$store.state.ui.dataLoaded;
    }

    login (): Promise<void> {
        return this.$store.dispatch('auth/login').then(() => {
            if (this.$route.query.url) {
                window.location.href = this.$route.query.url as string;
            }
            this.$nextTick(() => {
                this.$store.dispatch('auth/getToken');
            });
        });
    }

    get loggedIn () {
        return this.$store.getters['auth/loggedIn'];
    }

    get showHeader (): boolean {
        return !this.$route.meta?.hideHeader;
    }

    get showFooter (): boolean {
        return !this.$route.meta?.hideFooter;
    }

    get showBreadcrumbs (): boolean {
        return !this.$route.meta?.hideBreadcrumbs;
    }

    @Watch('loggedIn', { immediate: true })
    initialise (newVal: boolean) {
        if (newVal) {
            this.$store.dispatch('initialiseApp');
        }
    }
}
