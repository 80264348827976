










import { Component, Vue, Prop } from 'vue-property-decorator';
import ApplicationBubble from '@/components/ApplicationBubble.vue';
import Application from '@/models/Application';

@Component({ components: { ApplicationBubble } })
export default class ApplicationBubbleWrapper extends Vue {
    @Prop() applicationList!: Application[];

    @Prop({ default: 3 }) appsToShow!: number;

    get applicationsListCondensed (): Application[] {
        const totalArrayLength = this.applicationList.length;
        if (totalArrayLength <= this.appsToShow + 1) return this.applicationList;

        const appsNotShown = totalArrayLength - this.appsToShow;
        const newArray = this.applicationList.slice(0, this.appsToShow);
        const remainingNamesOfApps = this.applicationList
            .slice(this.appsToShow)
            .map((app) => app.name);
        const remainingAppsPlaceholder = new Application(
            'custom',
            remainingNamesOfApps.join(', '),
            '#8da8ad',
            `+${appsNotShown}`,
            null,
            false,
            null,
        );
        newArray.push(remainingAppsPlaceholder);
        return newArray;
    }
}
