import { ActionTree, GetterTree, MutationTree } from 'vuex';

interface LCPUserState {
    id: string;
    email: string;
    firstName: string;
    surname: string;
    department: string;
    internalAuthId: string;
    externalAuthId: string;
    active: boolean;
}

export const state: LCPUserState = {
    id: '',
    email: '',
    firstName: '',
    surname: '',
    department: '',
    internalAuthId: '',
    externalAuthId: '',
    active: false,
};

export const getters: GetterTree<LCPUserState, unknown> = {
    userInfo: (s: LCPUserState) => s,
};

export const actions: ActionTree<LCPUserState, unknown> = {};

export const mutations: MutationTree<LCPUserState> = {
    setUserFromApi (s, user: LCPUserState) {
        s.id = user.id;
        s.email = user.email;
        s.firstName = user.firstName;
        s.surname = user.surname;
        s.department = user.department;
        s.internalAuthId = user.internalAuthId || '';
        s.externalAuthId = user.externalAuthId || '';
        s.active = user.active || true;
    },
};

export default {
    namespace: true,
    state,
    getters,
    actions,
    mutations,
};
