export default class Team {
    public id!: string;

    public name!: string;

    public teamAdId!: string;

    public channel!: string;

    public channelName!: string;

    public channelEmailAddress!: string;

    constructor (
        id: string,
        name: string,
        teamAdId: string,
        channel: string,
        channelName: string,
        channelEmailAddress: string,

    ) {
        this.id = id;
        this.name = name;
        this.teamAdId = teamAdId;
        this.channel = channel;
        this.channelName = channelName;
        this.channelEmailAddress = channelEmailAddress;
    }
}
