var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "side-bar--content" }, [
    _c(
      "div",
      { staticClass: "search-input" },
      [
        _c("TextInput", {
          attrs: { placeholder: "Search Users", icon: "users" },
          model: {
            value: _vm.inputValue,
            callback: function ($$v) {
              _vm.inputValue = $$v
            },
            expression: "inputValue",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "user-list" }, [
      _c(
        "div",
        { staticClass: "user-list--inner" },
        _vm._l(_vm.filteredUsers, function (user, i) {
          return _c("UserSearchResult", {
            key: i,
            attrs: {
              data: user,
              searchText: _vm.inputValue,
              showApplication: "",
            },
            on: { "handle-click": _vm.goToUserAcess },
          })
        }),
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "side-bar-bottom" },
      [
        _c("font-awesome-icon", {
          staticClass: "icon user-add",
          attrs: { icon: ["fad", "user-plus"] },
        }),
        _c("p", [_vm._v("Add user into teams")]),
        _vm._m(0),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "teams-img" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/microsoft-teams.svg") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }