var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-page" },
    [
      _vm.dataLoaded
        ? _c("div", { staticClass: "container" }, [
            _c(
              "div",
              { staticClass: "orgs-plus-description" },
              [
                _c("DescriptionAuthorise"),
                _c(
                  "div",
                  { staticClass: "user-orgs" },
                  [
                    _c("router-link", {
                      attrs: { to: "/orgs/new-org", custom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var navigate = ref.navigate
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "btn-gradient condensed",
                                    on: { click: navigate },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["fal", "plus"] },
                                    }),
                                    _vm._v(" Add New Organisation "),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2883457695
                      ),
                    }),
                    _c(
                      "div",
                      { staticClass: "search-container" },
                      [
                        _c("TextInput", {
                          attrs: {
                            icon: "shield-alt",
                            placeholder: "Search organisations",
                          },
                          model: {
                            value: _vm.inputValue,
                            callback: function ($$v) {
                              _vm.inputValue = $$v
                            },
                            expression: "inputValue",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "user-orgs-container" }, [
                      _vm.filteredOrgs.length > 0
                        ? _c(
                            "div",
                            _vm._l(_vm.filteredOrgs, function (org, index) {
                              return _c(
                                "div",
                                {
                                  key: org.orgName + "-" + index,
                                  staticClass: "width item",
                                },
                                [
                                  _c("DetailedOrgCard", {
                                    key: org.orgName + "-" + index,
                                    staticClass: "detailed-card-wrapper",
                                    attrs: { org: org },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _c("div", [
                            _c("h2", [_vm._v("No organisations found")]),
                          ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _c("LoaderLogo", { attrs: { condensed: "" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }