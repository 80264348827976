var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header" }, [
    _c(
      "div",
      { staticClass: "header-wrapper container" },
      [
        _c(
          "div",
          { staticClass: "header-logo" },
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _c("img", {
                attrs: {
                  src: "/svgs/lcp-authorise-logo-new.svg",
                  alt: "LCP Authorise",
                },
              }),
            ]),
          ],
          1
        ),
        _c("Nav"),
        _c(
          "div",
          { staticClass: "logged-in-user" },
          [
            _c(
              "v-popover",
              { attrs: { offset: "10", placement: "bottom-end" } },
              [
                _c("div", { staticClass: "tooltip-target user-icon" }, [
                  _c("img", {
                    attrs: {
                      src:
                        "https://tesla0devclientdocument.blob.core.windows.net/pictures/" +
                        _vm.lcpUser.internalAuthId +
                        ".jpg",
                      alt: "pic",
                    },
                  }),
                ]),
                _c("template", { slot: "popover" }, [
                  _c("div", { staticClass: "logged-in-user-details" }, [
                    _c("p", [
                      _vm._v(" " + _vm._s(_vm.lcpUser.firstName) + " "),
                    ]),
                    _c("p", [_vm._v(_vm._s(_vm.lcpUser.department))]),
                    _c("p", [
                      _c(
                        "a",
                        { attrs: { href: "mailto:" + _vm.lcpUser.email } },
                        [_vm._v(_vm._s(_vm.lcpUser.email))]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "logged-in-user-actions" }, [
                    _c(
                      "div",
                      {
                        staticClass: "btn-gradient condensed",
                        on: { click: _vm.logout },
                      },
                      [
                        _vm._v(" logout "),
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fad", "sign-out-alt"] },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }