











































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';
import { required, email, minLength } from 'vuelidate/lib/validators';
import TextInput from '@/components/TextInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';

@Component({
    components: { TextInput, BaseButton },
    inheritAttrs: false,
})
export default class AddExternalUserAssociation extends Vue {
    @Prop() appId!: string;

    @Prop() orgId!: string;

    @Prop() getEditExternalUsersEmails!: string[];

    // #region START: validation classes
    get validationClassFName () {
        return {
            'border-danger':
        this.$v.$dirty
        && (!this.$v.firstName.minLength || !this.$v.firstName.required),
        };
    }

    get validationClassLName () {
        return {
            'border-danger':
        this.$v.$dirty
        && (!this.$v.lastName.minLength || !this.$v.lastName.required),
        };
    }

    get validationClassEmail () {
        return {
            'border-danger':
        this.$v.$dirty && (!this.$v.email.required || !this.$v.email.email),
        };
    }
    // #endregion END: validaiton classes

    // #region start user logic
    public email = '';

    public firstName = '';

    public lastName = '';

    @Validations()
        validations = {
            firstName: { required, minLength: minLength(2) },
            lastName: { required, minLength: minLength(2) },
            email: { required, email },
        };

    public submitStatus = '';

    get isDuplicateEmail (): boolean {
        return this.getEditExternalUsersEmails.includes(this.email);
    }

    addNewUser () {
        // check the form is valid
        this.$v.$touch();
        if (this.$v.$invalid || this.isDuplicateEmail) {
            this.submitStatus = 'ERROR';
        } else {
            // submit logic here
            this.$v.$reset();

            const payload: {
                email: string;
                firstname: string;
                surname: string;
            } = {
                email: this.email.toLowerCase(),
                firstname: this.firstName,
                surname: this.lastName,
            };
            this.$emit('addNewExtUser', payload);
            this.email = '';
            this.firstName = '';
            this.lastName = '';
        }
    }
}
