var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-search-wrapper" },
    [
      _c("TextInput", {
        attrs: { icon: _vm.inputIcon, placeholder: _vm.placeholder },
        on: {
          focus: function ($event) {
            return _vm.handleInputFocus(true)
          },
        },
        model: {
          value: _vm.inputValue,
          callback: function ($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue",
        },
      }),
      _vm.inputFocused
        ? _c("div", { staticClass: "response-container" }, [
            _vm.inputValue
              ? _c("div", { staticClass: "response-wrapper" }, [
                  !_vm.handleLoading
                    ? _c(
                        "div",
                        { staticClass: "user-responses" },
                        [
                          _vm._t("default", [_c("p", [_vm._v("No Results")])], {
                            filteredByInputValue: _vm.filteredByInputValue,
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.handleLoading
                    ? _c(
                        "div",
                        { staticClass: "loading-wrapper" },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "loader-icon",
                            attrs: { spin: "", icon: ["fad", "spinner-third"] },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }