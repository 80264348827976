var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-5" }, [
    _c(
      "div",
      { staticClass: "component-wrapper" },
      [
        _c("UserSearchWrapper", {
          attrs: { userSearch: true, data: _vm.usersData },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "col-7" }, [
      _c(
        "div",
        {
          staticClass: "open-sidebar",
          staticStyle: { cursor: "pointer" },
          on: {
            click: function ($event) {
              return _vm.changeSideBarContent("A")
            },
          },
        },
        [
          _vm._v(" sidebar w/usersearch "),
          _vm.sideBarContent === "A"
            ? _c(
                "Portal",
                { attrs: { to: "sideBarUserSearch" } },
                [_c("SideBarUserSearch")],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "open-sidebar",
          staticStyle: { cursor: "pointer" },
          on: {
            click: function ($event) {
              return _vm.changeSideBarContent("B")
            },
          },
        },
        [
          _vm._v(" sidebar w/test-text "),
          _vm.sideBarContent === "B"
            ? _c("Portal", { attrs: { to: "sideBarUserSearch" } }, [
                _c("p", { staticClass: "red" }, [_vm._v("None")]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {},
      [
        _c("TextFilterWithDropdown", {
          attrs: {
            inputIcon: "user-shield",
            data: _vm.userApps,
            searchBy: ["name", "email"],
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var filteredByInputValue = ref.filteredByInputValue
                return _vm._l(filteredByInputValue, function (data, i) {
                  return _c("UserSearchResult", {
                    key: i,
                    attrs: { data: data, showApplication: "", addToFilter: "" },
                  })
                })
              },
            },
          ]),
        }),
        _c("Breadcrumb"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }