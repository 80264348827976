


















































import { Component, Vue } from 'vue-property-decorator';
import TextInput from '@/components/TextInput.vue';
import UserAccessApplication from '@/components/UserAccessApplication.vue';
import UserTableRow from '@/components/UserTableRow.vue';
import ApplicationModel from '@/models/Application';
import Application from '@/components/Application.vue';
import Org from '@/components/Org.vue';
import OrgType from '@/models/Org';

@Component({
    components: {
        TextInput,
        UserTableRow,
        UserAccessApplication,
        Application,
        Org,
    },
})
export default class UserAcess extends Vue {
    public inputValue = '';

    public activeAppFilters: string[] = [];

    public activeOrgFilters: string[] = [];

    public areNoResults = false;

    public emptyTables: string[] = [];

    updateAppFilters (appId: string) {
        const index = this.activeAppFilters.indexOf(appId);
        if (index > -1) {
            return this.activeAppFilters.splice(index, 1); // remove
        }
        return this.activeAppFilters.push(appId); // add
    }

    updateOrgFilters (orgId: string) {
        const index = this.activeOrgFilters.indexOf(orgId);
        if (index > -1) {
            return this.activeOrgFilters.splice(index, 1); // remove
        }
        return this.activeOrgFilters.push(orgId); // add
    }

    get activeFilters () {
        return this.activeAppFilters;
    }

    get allApplications () {
        return this.$store.state.applications;
    }

    get allOrgs () {
        return this.$store.state.orgs;
    }

    get filteredApplications () {
    // filter from array of strings
        const filteredApps = this.allApplications.filter(
            (item: ApplicationModel) => !this.activeAppFilters.includes(item.id),
        );

        this.areNoResults = filteredApps.length === 0;
        return filteredApps;
    }

    addEmptyTable (appId: string) {
        if (!this.emptyTables.includes(appId)) {
            this.emptyTables.push(appId);
        }
    }

    removeEmptyTable (appId: string) {
        if (this.emptyTables.includes(appId)) {
            const index = this.emptyTables.indexOf(appId);
            this.emptyTables.splice(index, 1);
        }
    }

    reset () {
        this.inputValue = '';
        this.activeAppFilters = [];
        this.emptyTables = [];
    }

    get filteredOrgDetails () {
        const orgDetails = this.activeOrgFilters.map((elem) => this.$store.getters.getOrgDetails(elem),
        );
        return orgDetails.map((elem: OrgType) => elem.orgName);
    }
}
