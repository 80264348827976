import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import OrgApp from '@/views/OrgApp.vue';
import store from '@/store/index';
import Breadcrumb from '@/models/Breadcrumb';
import LoginView from '../views/Login.vue';
import AddNewOrgView from '../views/AddNewOrg.vue';
import UserView from '../views/User.vue';
import OrgDetailsView from '../views/OrgDetails.vue';
import OrgWithApplicationView from '../views/OrgWithApplication.vue';
import ApplicationsView from '../views/Applications.vue';
import ExternalAccessView from '../views/ExternalAccess.vue';
import UserAccessView from '../views/UserAccess.vue';
import UserAccessAltView from '../views/UserAccessAlt.vue';
import SearchView from '../views/Search.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'User Home',
        component: UserView,
        meta: {
            bcTitle: 'Home',
        },
    },
    {
        path: '/logout',
        name: 'Logout',
        component: LoginView,
        meta: {
            public: true,
        },
    },
    {
        path: '/org-app',
        name: 'OrgApp',
        component: OrgApp,
        meta: {
            bcTitle: 'Org App',
        },
    },

    {
        path: '/orgs',
        name: 'Organisations',
        redirect: '/',
    },
    {
        path: '/orgs/new-org',
        name: 'Add New Organisation',
        component: AddNewOrgView,
        meta: {
            bcTitle: 'Add New Organisation',
        },
    },
    {
        path: '/user/:userId',
        name: 'User',
        component: UserView,
        meta: {
            bcTitle: 'User',
        },
    },
    {
        path: '/org/:orgId',
        name: 'OrgDetails',
        component: OrgDetailsView,
        meta: {
            bcTitle: 'Organisation details',
        },
    },
    {
        path: '/org/:orgId/:apps/:appName',
        name: 'OrgWithApplication',
        component: OrgWithApplicationView,
    },

    {
        path: '/apps',
        name: 'Applications',
        component: ApplicationsView,
    },
    {
        path: '/external-access',
        name: 'External-access',
        component: ExternalAccessView,
    },
    {
        path: '/search',
        name: 'Search',
        component: SearchView,
    },
    {
        path: '/user-access',
        name: 'UserAccess',
        component: UserAccessView,
    },
    {
        path: '/user-access-alt',
        name: 'User Access',
        component: UserAccessAltView,
        meta: {
            bcTitle: 'User Access',
        },
    },

    {
        path: '/about',
        name: 'About',
        component: ExternalAccessView,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, from, next) => {
    // check if logged in

    // if (!to.meta?.public && !store.state.auth?.authResponse) {
    //   store.dispatch("checkForRedirectAfterLogin", to.fullPath);
    //   next("/login");
    // }
    //  set breadcrumb title
    let title: string = to.name || 'Unknown Route';
    if (to.meta?.bcTitle) {
        title = to.meta.bcTitle;
    }
    const breadcrumbObj = new Breadcrumb(title, to.fullPath);

    store.dispatch('addBreadcrumb', breadcrumbObj);

    if (store.state.ui.sideBar) {
        store.commit('toggleSideBar', false);
    }
    next();
});

export default router;
