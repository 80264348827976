import { render, staticRenderFns } from "./EditAppsSidebar.vue?vue&type=template&id=2afc83c0&scoped=true&"
import script from "./EditAppsSidebar.vue?vue&type=script&lang=ts&"
export * from "./EditAppsSidebar.vue?vue&type=script&lang=ts&"
import style0 from "./EditAppsSidebar.vue?vue&type=style&index=0&id=2afc83c0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2afc83c0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/authorise-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2afc83c0')) {
      api.createRecord('2afc83c0', component.options)
    } else {
      api.reload('2afc83c0', component.options)
    }
    module.hot.accept("./EditAppsSidebar.vue?vue&type=template&id=2afc83c0&scoped=true&", function () {
      api.rerender('2afc83c0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/EditAppsSidebar.vue"
export default component.exports