
















import { Component, Vue } from 'vue-property-decorator';
import BreadcrumbType from '@/models/Breadcrumb';

@Component
export default class Breadcrumb extends Vue {
    public breadcrumbs: BreadcrumbType[] = [];

    async removeBreadcrumbHistory (breadCrumbIndex: number, path: string) {
        try {
            await this.$store.dispatch('removeBreadcrumbHistory', breadCrumbIndex);

            if (this.$route.fullPath === path) {
                let title: string = this.$route.name || 'Unknown Route';
                if (this.$route.meta && this.$route.meta.name) {
                    title = this.$route.meta.bcTitle;
                }
                const breadcrumbObj = new BreadcrumbType(title, this.$route.fullPath);
                this.$store.dispatch('addBreadcrumb', breadcrumbObj);
                return;
            }
            this.$router.push(path);
        } catch (error) {
            // console.log(error);
        }
    }

    get breadcrumbsList () {
        const breadcrumbs = [...this.$store.state.ui.breadcrumbs];
        const noBreadcrumbsToShow = this.$store.state.ui.breadcrumbsToShow;

        if (breadcrumbs.length <= noBreadcrumbsToShow) {
            return breadcrumbs;
        }
        const entriesToRemove = breadcrumbs.length - noBreadcrumbsToShow;
        breadcrumbs.splice(0, entriesToRemove);
        return breadcrumbs;
    }
}
