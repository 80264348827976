var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.application.name,
          expression: "application.name",
        },
      ],
      staticClass: "application-wrapper",
      class: { small: _vm.small, large: _vm.large, "chip-bubble": _vm.chip },
      style: { backgroundColor: _vm.application.color },
    },
    [
      !_vm.selected
        ? [_vm._v(" " + _vm._s(_vm.application.abbreviation) + " ")]
        : [_c("font-awesome-icon", { attrs: { icon: ["far", "check"] } })],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }