



































import { Component, Vue } from 'vue-property-decorator';
import Button from '@/components/Button.vue';
import Multiselect from 'vue-multiselect';

@Component({ components: { Button, Multiselect } })
export default class Associations extends Vue {
    public options: string[] = ['none', 'user'];

    public selectedOption = '';
}
