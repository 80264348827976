



























import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import TextInput from '@/components/TextInput.vue';

@Component({ components: { TextInput }, inheritAttrs: false })
export default class TextFilterWithDropdown extends Vue {
    @Prop({ default: 'user-search' }) inputIcon?: string;

    @Prop() schemeData!: any[];

    @Prop() searchBy!: string[];

    @Prop({ default: 'search' }) placeholder?: string;

    @Prop() valueOverride?: string;

    public inputValue = '';

    public inputFocused = false;

    get filteredByInputValue () {
        return this.schemeData.filter((elem: Record<string, any>) => Object.keys(elem).some((item) => {
            // only filter by specified keys
            if (this.searchBy.includes(item)) {
                return elem[item]
                    .toLowerCase()
                    .includes(this.inputValue.toLowerCase());
            }
            // when no results
            return null;
        }),
        );
    }

    get handleLoading () {
        return !(this.inputValue.length > 1);
    }

    // DO NOT CHANGE 😡
    handleInputFocus (val: boolean) {
        this.inputFocused = val;
        this.inputValue = '';
    }

    @Watch('valueOverride', { immediate: true })
    updateInputValue (newVal: string) {
        if (newVal) {
            this.inputValue = newVal;
            if (newVal === '') {
                this.handleInputFocus(false);
            }
        }
    }
}
