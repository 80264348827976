var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "teams-search-wrapper" },
      [
        _c(
          "div",
          { staticClass: "search-wrapper-input" },
          [
            _c("TextInput", {
              attrs: { placeholder: "Please paste channel link here" },
              model: {
                value: _vm.teamChannelUrl,
                callback: function ($$v) {
                  _vm.teamChannelUrl = $$v
                },
                expression: "teamChannelUrl",
              },
            }),
          ],
          1
        ),
        _c(
          "base-button",
          {
            staticClass: "search-wrapper-button btn-gradient condensed",
            attrs: {
              disabled: _vm.isFetchingData || _vm.teamChannelUrl.length < 50,
              handleClick: _vm.searchTeams,
            },
          },
          [
            _c("font-awesome-icon", {
              staticClass: "icon icon-only",
              attrs: {
                icon: _vm.isFetchingData
                  ? ["fad", "spinner-third"]
                  : ["fal", "plus"],
                spin: _vm.isFetchingData,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm.hasError
      ? _c(
          "p",
          { staticClass: "error-message" },
          [
            _c("font-awesome-icon", {
              staticClass: "icon",
              attrs: { icon: ["fas", "exclamation"] },
            }),
            _vm._v(
              " Sorry, something went wrong. Please, check the channel Link and try again."
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }