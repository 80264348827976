















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DescriptionAuthorise extends Vue {
}
