var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard" }, [
    _c("div", { staticClass: "top" }, [
      _c("h2", { staticClass: "head-text" }, [
        _vm._v("ABC Pension fun > LCP visualise"),
      ]),
      _c("div", { staticClass: "data-id" }, [
        _vm._v("ebcabb28-89ad-4a02-8070-61b076e5fc17"),
      ]),
      _c(
        "div",
        { staticClass: "mapping-select" },
        [
          _c("h2", { staticClass: "subheading" }, [_vm._v("Mapping Id")]),
          _c("Multiselect", {
            staticClass: "select",
            attrs: {
              showLabels: false,
              options: _vm.options,
              "open-direction": "bottom",
            },
            model: {
              value: _vm.selectedOption,
              callback: function ($$v) {
                _vm.selectedOption = $$v
              },
              expression: "selectedOption",
            },
          }),
          _c("font-awesome-icon", {
            staticClass: "edit-icon",
            attrs: { icon: ["fad", "pencil"] },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "bottom" }, [
      _c("div", { staticClass: "left" }, [_c("AssociationsPannel")], 1),
      _c("div", { staticClass: "right" }, [
        _c("h3", { staticClass: "subheading" }, [_vm._v("Guess access")]),
        _c("div", { staticClass: "table-conatainer" }, [
          _c("div", { staticClass: "table" }, [
            _c(
              "div",
              { staticClass: "table-body" },
              [
                _vm._m(0),
                _vm._l(_vm.emailUserTypes, function (row, key) {
                  return _c("div", { key: key, staticClass: "tr" }, [
                    _c("div", { staticClass: "td" }, [
                      _vm._v(_vm._s(row.email)),
                    ]),
                    _c("div", { staticClass: "td" }, [
                      _vm._v(_vm._s(row.userType)),
                    ]),
                  ])
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tr" }, [
      _c("div", { staticClass: "td headers" }, [_vm._v("EMAIL")]),
      _c("div", { staticClass: "td headers" }, [_vm._v("USERTYPE")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }