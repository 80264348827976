var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "member-card card" }, [
    _c("p", { staticClass: "member-item" }, [
      _vm._v(_vm._s(_vm.member.email) + " "),
    ]),
    _c("p", { staticClass: "member-teams" }, [
      _vm.member.teamNames.length === 1
        ? _c("span", [_vm._v(_vm._s(_vm.member.teamNames[0]) + " ")])
        : _vm._e(),
      _vm.member.teamNames.length > 1 && !_vm.showAllTeams
        ? _c(
            "span",
            { staticClass: "dotted", on: { click: _vm.showAllTeamsForMember } },
            [
              _vm._v(
                "show all " + _vm._s(_vm.member.teamNames.length) + " teams"
              ),
            ]
          )
        : _vm._e(),
      _vm.member.teamNames.length > 1 && _vm.showAllTeams
        ? _c(
            "span",
            { on: { click: _vm.showAllTeamsForMember } },
            [
              _c("span", { staticClass: "dotted" }, [_vm._v("hide all teams")]),
              _vm._v(" "),
              _vm._l(_vm.member.teamNames, function (teamName) {
                return _c("p", { key: teamName }, [_vm._v(_vm._s(teamName))])
              }),
            ],
            2
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }