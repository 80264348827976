var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tabs-content" },
    _vm._l(_vm.steps, function (step, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "tab",
          class: {
            active: _vm.currentStep === index,
            complete: _vm.currentStep > index,
          },
        },
        [
          _c("div", { staticClass: "tab-circle" }, [
            _vm._v(" " + _vm._s(index + 1) + " "),
          ]),
          _c("span", { staticClass: "tab-title" }, [_vm._v(_vm._s(step))]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }