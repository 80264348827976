import { render, staticRenderFns } from "./AddNewOrg.vue?vue&type=template&id=293afddb&scoped=true&"
import script from "./AddNewOrg.vue?vue&type=script&lang=ts&"
export * from "./AddNewOrg.vue?vue&type=script&lang=ts&"
import style0 from "./AddNewOrg.vue?vue&type=style&index=0&id=293afddb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "293afddb",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/authorise-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('293afddb')) {
      api.createRecord('293afddb', component.options)
    } else {
      api.reload('293afddb', component.options)
    }
    module.hot.accept("./AddNewOrg.vue?vue&type=template&id=293afddb&scoped=true&", function () {
      api.rerender('293afddb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/AddNewOrg.vue"
export default component.exports