var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "breadcrumb" }, [
    _c(
      "div",
      { staticClass: "breadcrumb-wrapper" },
      _vm._l(_vm.breadcrumbsList, function (item, index) {
        return _c("div", { key: index, staticClass: "breadcrumb-items" }, [
          _c(
            "p",
            {
              on: {
                click: function ($event) {
                  return _vm.removeBreadcrumbHistory(index, item.url)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.title) + " ")]
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }