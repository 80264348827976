var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login" }, [
    _c(
      "div",
      { staticClass: "left" },
      [
        _vm._m(0),
        _c("p", [
          _vm._v(
            " Please log in or alternatively if you don't already have an account you can register using the link below "
          ),
        ]),
        _c("div", { staticClass: "loginActions" }, [
          _c(
            "div",
            { staticClass: "btn-gradient", on: { click: _vm.login } },
            [
              _vm._v(" Login "),
              _c("font-awesome-icon", {
                staticClass: "icon",
                attrs: { icon: ["fad", "user-friends"] },
              }),
            ],
            1
          ),
        ]),
        _c("Footer", { staticClass: "absolute" }),
      ],
      1
    ),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("img", { attrs: { src: "/svgs/lcp-authorise-logo-new.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right" }, [
      _c("div", { staticClass: "innerText" }, [
        _c("div", { staticClass: "heading" }, [
          _vm._v("Manage the permissions to LCP apps"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }