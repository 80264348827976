var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "teams-card" }, [
        _c("div", { staticClass: "teams-card--content" }, [
          _c("h3", [
            _vm._v(
              " " +
                _vm._s(_vm.team.name) +
                " - " +
                _vm._s(_vm.team.channelName) +
                " "
            ),
          ]),
        ]),
        _vm._m(0),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "teams-card--img" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/microsoft-teams.svg") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }