var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-application-summary" },
    [
      !_vm.showLoader
        ? [
            _vm._m(0),
            _c("div", { staticClass: "org-name" }, [
              _c("h2", [_vm._v("Organisation name")]),
              _c(
                "div",
                { staticClass: "card flex-space-between" },
                [
                  _c("span", [_vm._v(_vm._s(_vm.newOrg.orgName))]),
                  _c("font-awesome-icon", {
                    staticClass: "icon",
                    attrs: { icon: ["fad", "shield-alt"] },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "org-teams" },
              [
                _c("h2", [_vm._v("Associated teams")]),
                _vm._l(_vm.newOrg.teams, function (team) {
                  return _c(
                    "div",
                    {
                      key: team.teamId,
                      staticClass: "org-team teams-result-card",
                    },
                    [
                      _c("div", { staticClass: "teams-content" }, [
                        _c("span", { staticClass: "title" }, [
                          _vm._v(
                            " " +
                              _vm._s(team.name) +
                              " - " +
                              _vm._s(team.channelName) +
                              " "
                          ),
                        ]),
                      ]),
                      _vm._m(1, true),
                    ]
                  )
                }),
              ],
              2
            ),
            _c("div", { staticClass: "org-applications" }, [
              _c("h2", [_vm._v("Application access")]),
              _c(
                "div",
                { staticClass: "org-application-grid" },
                _vm._l(_vm.newOrg.applications, function (application) {
                  return _c(
                    "div",
                    { key: application, staticClass: "org-application" },
                    [
                      _c("Application", {
                        staticClass: "application-body",
                        attrs: {
                          application: _vm.getApplicationById(application),
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
          ]
        : [
            _c(
              "transition",
              { attrs: { name: "fade", mode: "out-in" } },
              [_vm.showLoader ? _c("LoaderLogo") : _vm._e()],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "summary" }, [
      _c("p", [
        _vm._v(
          ' Please review the information below and click "save" to add the organisation '
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "teams-logo" }, [
      _c("img", { attrs: { src: "/svgs/microsoft-teams.svg" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }