



























import { Component, Vue, Prop } from 'vue-property-decorator';
import TextInput from '@/components/TextInput.vue';
import UserSearchResult from '@/components/UserSearchResult.vue';
import UserResult from '@/models/UserResult';

@Component({ components: { TextInput, UserSearchResult } })
export default class SideBarUserSearch extends Vue {
    @Prop() users!: UserResult[];

    public inputValue = '';

    get isSideBarActive () {
        return this.$store.state.ui.sideBar;
    }

    get usersData () {
        return this.$store.state.users;
    }

    goToUserAcess (data: UserResult) {
        this.$router.push({
            path: `/user-access-alt/?filterByOrg=${this.$route.params.orgId}&filterByApp=${data.applications}&filterByName=${data.name}`,
        });
    }

    get filteredUsers () {
        if (this.inputValue) {
            return this.users.filter((elem: UserResult) => elem.name.toLowerCase().includes(this.inputValue.toLowerCase()),
            );
        }
        return this.users;
    }
}
