




import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ExternalAccess extends Vue {
    data () {
        return {};
    }
}
