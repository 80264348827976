




















































import {
    Vue, Prop, Component, Watch,
} from 'vue-property-decorator';
import TeamMember from '@/models/TeamMember';
import Team from '@/models/Team';
import TeamMembersSidebarCard from '@/components/TeamMembersSidebarCard.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import TextInput from '@/components/TextInput.vue';
import LoaderLogo from '@/components/LoaderLogo.vue';
import GraphApi from '@/api/graphApi';

@Component({
    components: {
        BaseButton,
        LoaderLogo,
        TextInput,
        TeamMembersSidebarCard,
    },
})

export default class OrgDetailsTeamMembers extends Vue {
    @Prop() orgId!: string;

    @Prop() orgDetails!: any;

    public teamMembers: Array<TeamMember> = [];

    public filteredByTeamName: Array<TeamMember> = [];

    public searchedByNameTeamMembers: Array<TeamMember> = [];

    public loadingMemberResults = false;

    public memberResultsNotFound = false;

    public sendingRefreshRequest = false;

    public searchRequest = '';

    activeTeam = 'all';

    isRefreshDisabled = false;

    unassociatedUsers = false;

    mounted () {
        this.checkForTeamMembers();
        this.filterByTeamName('all');
    }

    created () {
        const lastClickedTimestamp = Number(localStorage.getItem('lastClicked'));
        const cooldownInSeconds = 60;
        const currentTime = new Date().getTime();

        if (lastClickedTimestamp && currentTime - lastClickedTimestamp < cooldownInSeconds * 1000) {
            const remainingTime = cooldownInSeconds - Math.round((currentTime - lastClickedTimestamp) / 1000);
            this.disableRefresh(remainingTime);
        }
    }

    async refreshTeamsRequest (): Promise<void> {
        localStorage.setItem('lastClicked', new Date().getTime().toString());
        this.sendingRefreshRequest = true;
        const result: any = await GraphApi.updateOrgTeams(
            this.orgId,
            this.orgDetails.teams,
        );
        console.log(result);
        this.$emit('requestLatestData', 'viewTeamMembers', true);
    }

    disableRefresh (remainingTime: any) {
        this.isRefreshDisabled = true;
        setTimeout(() => {
            this.isRefreshDisabled = false;
        }, remainingTime * 1000);
    }

    public forceClose (): void {
        this.$store.commit('toggleSideBar', false);
        this.$store.dispatch('portalInView', '');
        this.loadingMemberResults = false;
    }

    get listOfTeams (): Array<string> {
        return this.orgDetails.teams.map((team: Team) => team.name);
    }

    async checkForTeamMembers (): Promise<void> {
        this.unassociatedUsers = false;
        this.loadingMemberResults = true;
        const results: any = await GraphApi.getAssociatedUsersForOrg({ OrgId: this.orgId });
        if (results.data.length > 0) {
            this.teamMembers = results.data;
            this.searchedByNameTeamMembers = this.teamMembers;
            this.memberResultsNotFound = false;
        } else {
            this.memberResultsNotFound = true;
        }
        this.loadingMemberResults = false;
        this.filteredByTeamName = this.teamMembers.map((teamMember) => {
            if (teamMember.teamNames[0] === null) {
                teamMember.teamNames[0] = '';
                this.unassociatedUsers = true;
            }
            return teamMember;
        });
    }

    showSearchResults () {
        if (this.searchRequest === '') {
            this.searchedByNameTeamMembers = this.filteredByTeamName;
            return;
        }
        this.searchedByNameTeamMembers = this.filteredByTeamName.filter((member: TeamMember) => {
            const email = member.email.toUpperCase();
            return email.includes(this.searchRequest.toUpperCase());
        });
        if (this.searchedByNameTeamMembers.length === 0) {
            this.memberResultsNotFound = true;
        } else {
            this.memberResultsNotFound = false;
        }
    }

    filterByTeamName (teamName: string): void {
        this.activeTeam = teamName;
        if (teamName === 'all') {
            this.filteredByTeamName = this.teamMembers.filter((teamMember) => teamMember.teamNames[0] !== null);
        } else {
            this.filteredByTeamName = this.teamMembers.filter((teamMember) => teamMember.teamNames.includes(teamName));
        }
    }

    @Watch('searchRequest', { immediate: true, deep: true })
    public showFilteredResults () {
        this.showSearchResults();
    }

    @Watch('filteredByTeamName', { immediate: true, deep: true })
    public updateSearchResults () {
        this.showSearchResults();
    }
}

