var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "navbar" },
    [
      _c(
        "router-link",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: "home",
              expression: "`home`",
            },
          ],
          attrs: { to: "/" },
        },
        [_c("font-awesome-icon", { attrs: { icon: ["fad", "home-alt"] } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }