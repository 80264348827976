var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: "Edit External Users",
          expression: "'Edit External Users'",
        },
      ],
      staticClass: "user-wrapper mr-1",
      class: { small: _vm.small, large: _vm.large, "chip-bubble": _vm.chip },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.$emit("userClick", _vm.application)
        },
      },
    },
    [_c("font-awesome-icon", { attrs: { icon: ["fad", "users"] } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }