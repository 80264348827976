var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "help-popovers" },
    [
      _c(
        "v-popover",
        { attrs: { offset: "16", placement: "top" } },
        [
          _c("span", [_vm._v("How do I locate the channel link?")]),
          _c("template", { slot: "popover" }, [
            _c("img", {
              staticStyle: { width: "500px" },
              attrs: { src: "/imgs/find-channel-link.gif" },
            }),
          ]),
        ],
        2
      ),
      _vm.withExplanation ? _c("span", [_vm._v(" | ")]) : _vm._e(),
      _vm.withExplanation
        ? _c(
            "v-popover",
            { attrs: { offset: "16", placement: "top" } },
            [
              _c("span", [_vm._v("What is this used for?")]),
              _c("template", { slot: "popover" }, [
                _c("p", [
                  _vm._v(
                    'This Teams association will provide "LCP authorise" with the list of individuals who will have access to this organisation\'s tools. This will update automatically when LCP people are added and removed from this Team.'
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Notifications for this organisation will be sent to the channel provided."
                  ),
                ]),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }