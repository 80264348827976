var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editAppsSidebar" },
    [
      _vm.sendingRequest
        ? _c("LoaderLogo")
        : [
            _c("div", { staticClass: "header" }, [
              _c("h2", [_vm._v("Edit Teams Channels")]),
            ]),
            _c("InstructionTeamLink", { attrs: { withExplanation: true } }),
            _c("div", { staticClass: "body" }, [
              _c(
                "div",
                { staticClass: "addNewTeam" },
                [
                  _c("AddTeamsWithLink", {
                    attrs: {
                      valueOverride: _vm.teamInputValue,
                      placeholder: "Please paste channel link here",
                    },
                    on: { "add-team-association": _vm.addTeamAssociation },
                  }),
                ],
                1
              ),
              _vm.teamAlreadyExists
                ? _c("p", { staticClass: "error-message" }, [
                    _vm._v("Sorry, this team is already added."),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "selected-teams" },
                _vm._l(_vm.teamListSelection, function (team, index) {
                  return _c(
                    "div",
                    { key: team.id },
                    [
                      _c("EditTeamsSidebarTeamCard", {
                        key: team.id,
                        attrs: {
                          team: team,
                          index: index,
                          notLastCompany: _vm.notLastCompany,
                          newTeamTeamAdId: _vm.newTeamTeamAdId,
                          isSuccessMessage: _vm.isSuccessMessage(team),
                          isErrorMessage: _vm.isErrorMessage(team),
                          errorMessage: _vm.getTeamStatusMessage(team),
                          showTeamsUpdateErrorMessages:
                            _vm.showTeamsUpdateErrorMessages,
                        },
                        on: {
                          "remove-team-association": _vm.removeTeamAssociation,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
            _c(
              "div",
              { staticClass: "actions" },
              [
                _c(
                  "BaseButton",
                  {
                    staticClass: "btn-gradient condensed inline",
                    attrs: {
                      handleClick: _vm.updateTeamsRequest,
                      disabled: _vm.sendingRequest,
                    },
                  },
                  [
                    _vm._v("Update "),
                    _c("font-awesome-icon", {
                      staticClass: "icon",
                      attrs: { icon: ["fad", "save"] },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }