var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "position-relative" }, [
    _c("h4", [_vm._v("Add an External User")]),
    _c(
      "div",
      { staticClass: "teams-search-wrapper my-3" },
      [
        _c(
          "div",
          { staticClass: "search-wrapper-input mr-1" },
          [
            _c("TextInput", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: "Enter first name",
                  expression: "'Enter first name'",
                },
              ],
              attrs: {
                placeholder: "Enter first name",
                inputBorder: false,
                validationClass: _vm.validationClassFName,
              },
              model: {
                value: _vm.firstName,
                callback: function ($$v) {
                  _vm.firstName = $$v
                },
                expression: "firstName",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "search-wrapper-input mr-1" },
          [
            _c("TextInput", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: "Enter last name",
                  expression: "'Enter last name'",
                },
              ],
              attrs: {
                placeholder: "Enter last name",
                inputBorder: false,
                validationClass: _vm.validationClassLName,
              },
              model: {
                value: _vm.lastName,
                callback: function ($$v) {
                  _vm.lastName = $$v
                },
                expression: "lastName",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "search-wrapper-input mr-1" },
          [
            _c("TextInput", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: "Enter an email address",
                  expression: "'Enter an email address'",
                },
              ],
              attrs: {
                placeholder: "Enter email address",
                inputBorder: false,
                validationClass: _vm.validationClassEmail,
              },
              model: {
                value: _vm.email,
                callback: function ($$v) {
                  _vm.email = $$v
                },
                expression: "email",
              },
            }),
          ],
          1
        ),
        _c(
          "base-button",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: "Add",
                expression: "'Add'",
              },
            ],
            staticClass: "search-wrapper-button btn-gradient condensed",
            attrs: { disabled: false, handleClick: _vm.addNewUser },
          },
          [
            _c("font-awesome-icon", {
              staticClass: "icon icon-only",
              attrs: { icon: ["far", "check"] },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "d-flex" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.$v.firstName.minLength && _vm.$v.$dirty,
              expression: "!$v.firstName.minLength && $v.$dirty",
            },
          ],
          staticClass: "validation-error",
        },
        [_vm._v(" Min length 2 chars ")]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.$v.firstName.required && _vm.$v.$dirty,
              expression: "!$v.firstName.required && $v.$dirty",
            },
          ],
          staticClass: "validation-error",
        },
        [_vm._v(" First Name is required ")]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.$v.lastName.minLength && _vm.$v.$dirty,
              expression: "!$v.lastName.minLength && $v.$dirty",
            },
          ],
          staticClass: "validation-error",
        },
        [_vm._v(" Min length 2 chars ")]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.$v.lastName.required && _vm.$v.$dirty,
              expression: "!$v.lastName.required && $v.$dirty",
            },
          ],
          staticClass: "validation-error",
        },
        [_vm._v(" Last name is required ")]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.$v.email.required && _vm.$v.$dirty,
              expression: "!$v.email.required && $v.$dirty",
            },
          ],
          staticClass: "validation-error",
        },
        [_vm._v(" Email is required ")]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.$v.email.email && _vm.$v.$dirty,
              expression: "!$v.email.email && $v.$dirty",
            },
          ],
          staticClass: "validation-error",
        },
        [_vm._v(" Email is not valid ")]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isDuplicateEmail && _vm.$v.$dirty,
              expression: "isDuplicateEmail && $v.$dirty",
            },
          ],
          staticClass: "validation-error",
        },
        [_vm._v(" Email already exists ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }