


























import { Component, Prop, Vue } from 'vue-property-decorator';
import UserResult from '@/models/UserResult';
import TextHighlight from 'vue-text-highlight';

@Component({ components: { TextHighlight } })
export default class UserTableRow extends Vue {
    @Prop() userDetails!: UserResult;

    @Prop() query!: string;

    gotToUser () {
        this.$router.push({
            path: `/user/${this.userDetails.id}`,
        });
    }

    findOrgId (orgName: string) {
        return this.$store.getters.getOrgDetailsByName(orgName).id;
    }
}
