

























































import { Component, Vue } from 'vue-property-decorator';
import UserSearchWrapper from '@/components/UserSearchWrapper.vue';
import UserApps from '@/components/UserApps.vue';

import UserSearchResult from '@/components/UserSearchResult.vue';

import SideBarUserSearch from '@/components/SideBarUserSearch.vue';

import TextFilterWithDropdown from '@/components/TextFilterWithDropdown.vue';
import UserResult from '@/models/UserResult';

import Breadcrumb from '@/components/Breadcrumb.vue';

@Component({
    components: {
        UserSearchWrapper,
        UserApps,
        SideBarUserSearch,
        TextFilterWithDropdown,
        UserSearchResult,
        Breadcrumb,
    },
})
export default class Search extends Vue {
    public sideBarContent = 'A';

    get usersData () {
        return this.$store.state.users;
    }

    slotData = 'hello scoped slot';

    changeSideBarContent (content: string) {
        this.sideBarContent = content;
        this.toggleSideBar();
    }

    toggleSideBar () {
        let isSideBarActive = this.sideBarActive;
        isSideBarActive = !isSideBarActive;
        this.$store.commit('toggleSideBar', isSideBarActive);
    }

    get sideBarActive () {
        return this.$store.state.ui.sideBar;
    }

    public userApps: UserResult[] = [
        {
            name: 'Gareth Goodram',
            email: 'gareth.goodram@lcp.uk.com',
            userType: 'Org Admin',
            id: 'a7827e3a-a10c-11eb-bcbc-0242ac130002',
            role: 'Partner',
            teams: [
                'a7b1fed3-7972-4c78-bc09-b3da56dd6c3c',
                '13064823-e240-490f-9528-d01733f18fd7',
            ],
            org: ['Telefonica UK Pension Plan', 'ABC Pension Org'],
            applications: [
                '4c8e6a7c-a51e-40b2-9cc3-1345a14168ba',
                'da129c14-f776-4402-8aaf-7e37192bca9d',
                'bfeab0cb-0372-4a52-89ab-e9be90eb7bae',
                'c8a95bee-42dd-48f4-aa37-2d78dda4a6f3',
            ],
        },
        {
            name: 'Claire Goodram',
            email: 'claire.goodram@lcp.uk.com',
            userType: 'Org Admin',
            id: 'ad527266-a10c-11eb-bcbc-0242ac130002',
            role: 'Consultant',
            teams: [
                'a7b1fed3-7972-4c78-bc09-b3da56dd6c3c',
                '13064823-e240-490f-9528-d01733f18fd7',
            ],
            org: ['ABC Pension Org', 'Telefonica UK Pension Plan'],
            applications: [
                '4c8e6a7c-a51e-40b2-9cc3-1345a14168ba',
                'da129c14-f776-4402-8aaf-7e37192bca9d',
                'c8a95bee-42dd-48f4-aa37-2d78dda4a6f3',
            ],
        },
        {
            name: 'Clark Goodram',
            email: 'clark.goodram@lcp.uk.com',
            userType: 'User',
            id: 'b2c34c84-a10c-11eb-bcbc-0242ac130002',
            role: 'Analyst',
            teams: [
                'a7b1fed3-7972-4c78-bc09-b3da56dd6c3c',
                '13064823-e240-490f-9528-d01733f18fd7',
            ],
            org: ['CDE Pension Org'],
            applications: [
                '4c8e6a7c-a51e-40b2-9cc3-1345a14168ba',
                'bfeab0cb-0372-4a52-89ab-e9be90eb7bae',
                'c8a95bee-42dd-48f4-aa37-2d78dda4a6f3',
            ],
        },
        {
            name: 'Emma Matheson',
            email: 'emma.matheson@lcp.uk.com',
            userType: 'User',
            id: 'a7827e3a-a10c-112b-bcbc-0242ac130002',
            role: 'User',
            teams: [
                'a7b1fed3-7972-4c78-bc09-b3da56dd6c3c',
                '13064823-e240-490f-9528-d01733f18fd7',
            ],
            org: ['CDE Pension Org'],
            applications: [
                '4c8e6a7c-a51e-40b2-9cc3-1345a14168ba',
                'da129c14-f776-4402-8aaf-7e37192bca9d',
                'bfeab0cb-0372-4a52-89ab-e9be90eb7bae',
                'c8a95bee-42dd-48f4-aa37-2d78dda4a6f3',
            ],
        },
    ];
}
