



















































































import {
    Component, Vue, Watch, Prop,
} from 'vue-property-decorator';
import Application from '@/components/Application.vue';
import Org from '@/components/Org.vue';
import OrgType from '@/models/Org';
import TextInput from '@/components/TextInput.vue';
import Checkbox from '@/components/Checkbox.vue';
import Chip from '@/components/Chip.vue';
import FilterOptions from '@/models/FilterOptions';

import TextFilterWithDropdown from '@/components/TextFilterWithDropdown.vue';
import UserSearchResult from '@/components/UserSearchResult.vue';
import OrgSearchResult from '@/components/OrgSearchResult.vue';
import UserResult from '@/models/UserResult';
// import B2cUser from "@/models/B2cUser";

@Component({
    components: {
        Application,
        Org,
        TextInput,
        Checkbox,
        Chip,
        TextFilterWithDropdown,
        UserSearchResult,
        OrgSearchResult,
    },
})
export default class FilterPanelAlt extends Vue {
    @Prop() urlQueries!: FilterOptions;

    @Prop() numberOfPeople!: number;

    public inputValue = '';

    public activeAppFilters: string[] = [];

    public activeOrgFilters: string[] = [];

    public activeNameFilters: string[] = [];

    // public nw = new UserResult(); //

    public nameSearchInput = '';

    public orgSearchInput = '';

    public filterOptions: FilterOptions = {
        activeAppFilters: [],
        activeOrgFilters: [],
        activeNameFilters: [],
    };

    created () {
        this.filterOptions.activeAppFilters = [...this.urlQueries.activeAppFilters];

        this.activeAppFilters = [...this.urlQueries.activeAppFilters];

        this.filterOptions.activeOrgFilters = [...this.urlQueries.activeOrgFilters];

        this.activeOrgFilters = [...this.urlQueries.activeOrgFilters];

        this.activeNameFilters = [...this.urlQueries.activeNameFilters];
        this.filterOptions.activeNameFilters = [
            ...this.urlQueries.activeNameFilters,
        ];
    }

    get allUsers () {
        return this.$store.state.abcUsersAdmin;
    }

    get unselectedUsers () {
        return this.allUsers.filter(
            (elem: UserResult) => !this.filterOptions.activeNameFilters.includes(elem.name),
        );
    }

    get allApplications () {
        return this.$store.state.applications;
    }

    get allOrgs () {
        return this.$store.state.orgs;
    }

    get selectedOrgFilters () {
        return this.allOrgs.filter((elem: OrgType) => this.filterOptions.activeOrgFilters.includes(elem.id),
        );
    }

    get unselectedOrgFilters () {
        return this.allOrgs.filter(
            (elem: OrgType) => !this.filterOptions.activeOrgFilters.includes(elem.id),
        );
    }

    get selectedAllAppFilters () {
        return this.activeAppFilters.length === 4;
    }

    selectAllAppFilters () {
        if (this.activeAppFilters.length === 4) {
            this.activeAppFilters = [];
            this.filterOptions.activeAppFilters = []; // new filterOptions object
        } else {
            // new filterOptions object
            this.filterOptions.activeAppFilters = [...this.allApplications].map(
                (elem) => elem.id,
            );
            this.activeAppFilters = [...this.allApplications].map(
                (elem) => elem.id,
            );
        }
    }

    updateAppFilters (appId: string) {
        const index = this.activeAppFilters.indexOf(appId);
        if (index > -1) {
            this.filterOptions.activeAppFilters.splice(index, 1); // new filterOptions object
            return this.activeAppFilters.splice(index, 1); // remove
        }
        // new filterOptions object
        this.filterOptions.activeAppFilters.push(appId); // new filterOptions object
        return this.activeAppFilters.push(appId);
        // add
    }

    // TODO refactor these similar functions
    updateOrgFilters (orgId: string) {
        (this.$refs.orgFilter as TextFilterWithDropdown).handleInputFocus(false);
        const index = this.activeOrgFilters.indexOf(orgId);
        if (index > -1) {
            this.filterOptions.activeOrgFilters.splice(index, 1); // new filterOptions object
            return this.activeOrgFilters.splice(index, 1); // remove
        }
        // new filterOptions object
        this.filterOptions.activeOrgFilters.push(orgId);
        return this.activeOrgFilters.push(orgId);
        // add
    }

    updateUserNameFilters (user: string) {
        const index = this.activeNameFilters.indexOf(user);
        if (index > -1) {
            this.filterOptions.activeNameFilters.splice(index, 1); // new filterOptions object
            return this.activeNameFilters.splice(index, 1); // remove
        }
        // new filterOptions object
        this.filterOptions.activeNameFilters.push(user);
        return this.activeNameFilters.push(user);
        // add
    }

    updateUserNameFiltersFromSearch (user: UserResult) {
        (this.$refs.userFilter as TextFilterWithDropdown).handleInputFocus(false);
        const index = this.activeNameFilters.indexOf(user.name);
        if (index > -1) {
            this.filterOptions.activeNameFilters.splice(index, 1); // new filterOptions object
            return this.activeNameFilters.splice(index, 1); // remove
        }
        // new filterOptions object
        this.filterOptions.activeNameFilters.push(user.name);
        return this.activeNameFilters.push(user.name);
        // add
    }

    @Watch('filterOptions', { immediate: true, deep: true })
    emitFilterOptions (filterOptions: FilterOptions) {
        this.$emit('update-filter-options', filterOptions);
    }
}
