var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-search-wrapper" },
    [
      _c("TextInput", {
        attrs: { placeholder: "Search user access", icon: "user-shield" },
        model: {
          value: _vm.inputValue,
          callback: function ($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue",
        },
      }),
      _c("div", { staticClass: "response-container" }, [
        _vm.inputValue && _vm.userSearch
          ? _c("div", { staticClass: "response-wrapper" }, [
              _c(
                "div",
                { staticClass: "user-responses" },
                _vm._l(_vm.filteredByInputValue, function (data, i) {
                  return _c("UserSearchResult", {
                    key: i,
                    attrs: {
                      data: data,
                      searchText: _vm.inputValue,
                      showApplication: true,
                    },
                  })
                }),
                1
              ),
              _vm.handleLoading
                ? _c(
                    "div",
                    { staticClass: "loading-wrapper" },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "loader-icon",
                        attrs: { spin: "", icon: ["fad", "spinner-third"] },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }