











































import { Component, Vue } from 'vue-property-decorator';
import WizardTabs from '@/components/WizardTabs.vue';
import AddOrgApplications from '@/components/AddOrgApplications.vue';
import AddTeamChannelAssociation from '@/components/AddTeamChannelAssociation.vue';
import AddOrgSummary from '@/components/AddOrgSummary.vue';
import AddOrgName from '@/components/AddOrgName.vue';
import BaseButton from '@/components/base/BaseButton.vue';

import { NewOrgTemplate } from '@/models/Org';
import Teams from '@/models/Team';
// import Application from "@/components/Application.vue";

@Component({
    components: {
        WizardTabs,
        AddOrgApplications,
        AddTeamChannelAssociation,
        AddOrgSummary,
        AddOrgName,
        BaseButton,
    },
})
export default class AddNewOrg extends Vue {
    public newOrg: Record<string, unknown> = {
        orgName: '',
        isPensionScheme: false,
        applications: [],
        teams: [],
    };

    public statusIsValid = false;

    public steps: Record<string, string | boolean>[] = [
        {
            title: 'Organisation name',
            componentName: 'AddOrgName',
            showStepButtons: false,
        },
        {
            title: 'Team association',
            componentName: 'AddTeamChannelAssociation',
            showStepButtons: true,
        },
        {
            title: 'Applications',
            componentName: 'AddOrgApplications',
            showStepButtons: true,
        },
        {
            title: 'Summary',
            componentName: 'AddOrgSummary',
            showStepButtons: true,
        },
    ];

    public appListSelection: string[] = [];

    public teamSelection: Teams[] = [];

    public currentStep = 0;

    public successfull = false;

    public showLoader = false;

    previous () {
        if (this.currentStep > 0) {
            this.currentStep -= 1;
        } else {
            this.currentStep = 0;
        }
    }

    next () {
        if (this.currentStep === this.steps.length - 1) {
            this.saveNewOrg();
        } else {
            this.currentStep += 1;
        }
    }

    get buttonText () {
        return this.currentStep === this.steps.length - 1 ? 'Save' : 'Next';
    }

    jumpToStep (stepIndex: number): void {
        this.currentStep = stepIndex;
    }

    updateOrgData (orgData: string | string[], orgKey: string) {
        Vue.set(this.newOrg, orgKey, orgData);
    }

    created () {
        if (this.$route.query.scheme === 'true') {
            this.newOrg.isPensionScheme = true;
        }
    }

    async saveNewOrg () {
        let teamsToAdd = [];
        teamsToAdd = (this.newOrg.teams as Teams[]).map((team: Teams) => ({
            name: team.name,
            teamAdId: team.teamAdId,
            channel: team.channel,
            channelName: team.channelName,
        }));

        let applicatiosToAdd = [];

        applicatiosToAdd = (this.newOrg.applications as string[]).map(
            (appId: string) => ({
                id: appId,
            }),
        );

        const orgForSaving: NewOrgTemplate = {
            orgName: this.newOrg.orgName as string,
            teams: teamsToAdd,
            apps: applicatiosToAdd,
        };
        this.showLoader = true;
        const newId = await this.$store.dispatch('addNewOrg', orgForSaving);
        this.$router.push(`/org/${newId.data}?added=true`);
        this.showLoader = false;
    }

    setValidity (status: boolean) {
        this.statusIsValid = status;
    }

    get dynamicWizardComponent () {
        return this.steps[this.currentStep];
    }

    get stepTitles () {
        return this.steps.map((step) => step.title);
    }
}
