var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "org-with-application" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "org-details" }, [
        _c("div", { staticClass: "org-details--name" }, [
          _c("h1", [_vm._v(_vm._s(_vm.orgDetails.orgName))]),
        ]),
        _c("div", { staticClass: "org-details--body" }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }