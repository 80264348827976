



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class WizardTabs extends Vue {
    @Prop() currentStep!: number;

    @Prop() steps!: string[];
}
