var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-access-page" }, [
    _c(
      "div",
      { staticClass: "search-wrapper col-4" },
      [
        _c("TextInput", {
          attrs: { icon: "search", placeholder: "Search" },
          model: {
            value: _vm.inputValue,
            callback: function ($$v) {
              _vm.inputValue = $$v
            },
            expression: "inputValue",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "container filters" },
      [
        _vm._l(_vm.allApplications, function (app, i) {
          return _c("Application", {
            key: i,
            class: { "filter-enabled": _vm.activeAppFilters.includes(app.id) },
            attrs: { application: app, useAsFilter: "" },
            on: { applicationClickHandler: _vm.updateAppFilters },
          })
        }),
        _vm._l(_vm.allOrgs, function (org, j) {
          return _c("Org", {
            key: "org-" + j,
            class: { "filter-enabled": _vm.activeOrgFilters.includes(org.id) },
            attrs: { org: org },
            on: { orgClickHandler: _vm.updateOrgFilters },
          })
        }),
      ],
      2
    ),
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "user-access-page--inner" },
        _vm._l(_vm.filteredApplications, function (application, l) {
          return _c("UserAccessApplication", {
            key: "org-" + l,
            class: { hidden: _vm.emptyTables.includes(application.id) },
            attrs: {
              application: application,
              query: _vm.inputValue,
              filteredorgDetails: _vm.filteredorgDetails,
            },
            on: {
              areNoResults: _vm.addEmptyTable,
              areResults: _vm.removeEmptyTable,
            },
          })
        }),
        1
      ),
    ]),
    _vm.areNoResults || _vm.emptyTables.length === 4
      ? _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "user-access-page--inner no-results" },
            [
              _vm._v(
                " Could not find any results, please refine your search. "
              ),
              _c(
                "font-awesome-layers",
                { staticClass: "fa-6x no-results-icon" },
                [
                  _c("font-awesome-icon", { attrs: { icon: ["fad", "list"] } }),
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "slash"], transform: "left-2" },
                  }),
                ],
                1
              ),
              false
                ? _c("button", { on: { click: _vm.reset } }, [_vm._v("reset")])
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }