var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tr user-table-row" }, [
    _c("div", { staticClass: "td" }, [
      _c(
        "div",
        { staticClass: "name link", on: { click: _vm.gotToUser } },
        [
          _c("text-highlight", { attrs: { queries: _vm.query } }, [
            _vm._v(_vm._s(_vm.userDetails.name)),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "email" },
        [
          _c("text-highlight", { attrs: { queries: _vm.query } }, [
            _vm._v(_vm._s(_vm.userDetails.email)),
          ]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "td org" }, [
      _c(
        "div",
        { staticClass: "box" },
        _vm._l(_vm.userDetails.org, function (org, i) {
          return _c(
            "div",
            { key: i },
            [
              _c(
                "router-link",
                {
                  staticClass: "link",
                  attrs: { to: "/org/" + _vm.findOrgId(org) },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(org) +
                      _vm._s(i === _vm.userDetails.org.length - 1 ? "" : ",") +
                      " "
                  ),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "td" }, [_vm._v("Admin")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }