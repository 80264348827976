import Vue from 'vue';
import Vuex from 'vuex';
import Org from '@/models/Org';
import Application from '@/models/Application';
import Team from '@/models/Team';
import UserResult from '@/models/UserResult';
import Breadcrumb from '@/models/Breadcrumb';
import GraphApi from '@/api/graphApi';
import modules from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
    modules,
    state: {
        ui: {
            editEnabled: true,
            sideBar: false,
            sideBarPortalName: '',
            isDeveloper: false,
            appManagerPopUp: {
                buttonLabel: '',
                label: '',
                show: false,
            },
            breadcrumbs: new Array<Breadcrumb>(),
            breadcrumbsToShow: 4,
            dataLoaded: false,
        },
        redirectAfterLogin: null,
        isLoggedIn: true,
        orgs: new Array<Org>(),
        applications: new Array<Application>(),
        abcUsersAdmin: [],
        abcUsers: [],
        teams: new Array<Team>(),
        users: [],
    },
    mutations: {
        updateOrgData (state, orgArray: Org[]) {
            Vue.set(state, 'orgs', orgArray);
        },
        updateApplicationsData (state, payload: Application[]) {
            Vue.set(state, 'applications', payload);
        },
        updateTeams (state, payload: Team[]) {
            Vue.set(state, 'teams', payload);
        },
        updateEditEnabled (state, payload: boolean) {
            Vue.set(state.ui, 'editEnabled', payload);
        },
        updateUserData (state, payload: UserResult[]) {
            Vue.set(state, 'users', payload);
        },
        updateIsDeveloper (state, payload: boolean) {
            Vue.set(state.ui, 'isDeveloper', payload);
        },
        addNewOrg (state, org: Org) {
            state.orgs.push(org);
        },
        updateAddToAppList (state, payload) {
            (state.orgs[payload.orgIndex] as Org).applications.push(payload.id);
        },
        updateRemoveToAppList (state, payload) {
            (state.orgs[payload.orgIndex] as Org).applications.splice(
                payload.appIndex,
                1,
            );
        },
        updateAppActionConfirmation (state, payload) {
            Vue.set(state.ui, 'appActionConfirmation', payload);
        },

        updateAbcUsersAdmin (state, payload: UserResult[]) {
            Vue.set(state, 'abcUsersAdmin', payload);
        },
        updateAbcUsers (state, payload: UserResult[]) {
            Vue.set(state, 'abcUsers', payload);
        },
        toggleSideBar (state, payload: boolean) {
            Vue.set(state.ui, 'sideBar', payload);
        },

        updateBreadcrumbsOrgName (state, breadcrumbObj) {
            Vue.set(
                state.ui.breadcrumbs[breadcrumbObj.index],
                'title',
                breadcrumbObj.name,
            );
        },
        addNewBreadcrumb (state, breadcrumbObject: Breadcrumb) {
            state.ui.breadcrumbs.push(breadcrumbObject);
        },

        updateBreadcrumbsArray (state, breadcrumbArray: Breadcrumb[]) {
            Vue.set(state.ui, 'breadcrumbs', breadcrumbArray);
        },
        setRouteForAfterLogin (state, route: string) {
            Vue.set(state, 'redirectAfterLogin', route);
        },
        setDataLoaded (state, status: boolean) {
            Vue.set(state.ui, 'dataLoaded', status);
        },
        SET_PORTAL_NAME (state, name: string) {
            Vue.set(state.ui, 'sideBarPortalName', name);
        },
    },
    actions: {
        async initialiseApp ({ commit }) {
            const apps: any = await GraphApi.getApps();
            const appsNoAuthz: Application[] = apps.data.filter(
                (app: Application) => app.name !== 'Authz',
            );
            commit('updateApplicationsData', appsNoAuthz);
            // const userAccess: any = await GraphApi.getUserAccess();
            // console.log(userAccess);
            const user: any = await GraphApi.getUser();
            commit('lcpUser/setUserFromApi', user.data);
            commit('setDataLoaded', true);
        },
        async addNewOrg (context, newOrgTemplate: any) {
            const newOrgResult = await GraphApi.addNewOrg(newOrgTemplate);
            return newOrgResult;
            // commit("addNewOrg", org);
        },
        addBreadcrumb ({ commit }, breadcrumbObj: Breadcrumb) {
            // FEEDBACK - lets start to add the Breadcrumb type in ts :
            commit('addNewBreadcrumb', breadcrumbObj);
        },
        removeBreadcrumbHistory (
            { commit, state },
            frontendBreadcrumbIndex: number,
        ) {
            const breadcrumbLength = state.ui.breadcrumbs.length;

            let storeIndex = frontendBreadcrumbIndex;
            const noBreadcrumbsToShow = state.ui.breadcrumbsToShow;
            if (breadcrumbLength > noBreadcrumbsToShow) {
                storeIndex = breadcrumbLength - (noBreadcrumbsToShow - frontendBreadcrumbIndex);
            }
            const currentBreadcrumbArray = [...state.ui.breadcrumbs];
            currentBreadcrumbArray.splice(storeIndex);
            commit('updateBreadcrumbsArray', currentBreadcrumbArray);
            return true;
        },
        addOrgNameBreadcrumb ({ commit, state }, name) {
            const breadcrumbs: Breadcrumb[] = state.ui.breadcrumbs;
            if (breadcrumbs.length > 0) {
                commit('updateBreadcrumbsOrgName', {
                    index: breadcrumbs.length - 1,
                    name,
                });
            }
        },
        checkForRedirectAfterLogin ({ commit }, fullPath: string) {
            if (fullPath && fullPath.length > 0) {
                commit('setRouteForAfterLogin', fullPath);
            }
        },
        portalInView ({ commit }, portalName: string) {
            commit('SET_PORTAL_NAME', portalName);
        },
    },
    getters: {
        getOrgDetails: (state) => (id: string) => state.orgs.find((org: Org) => org.id === id),
        getOrgDetailsByName: (state) => (orgName: string) => state.orgs.find((org: Org) => org.orgName === orgName),
        getApplications: (state) => (ids: string[]) => ids.map((id: string) => state.applications.find((app: Application): boolean => app.id === id),
        ),
        getApplicationById: (state) => (id: string) => state.applications.find((app: Application): boolean => app.id === id),
        getTeams: (state) => (teams: string[]) => teams.map((id: string) => state.teams.find((team: Team): boolean => team.teamAdId === id),
        ),
        getTeamById: (state) => (teamId: string) => state.teams.find((team: Team) => team.teamAdId === teamId),
        getPortalName: (state) => state.ui.sideBarPortalName,
    },
});
