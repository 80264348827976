






import { Component, Prop, Vue } from 'vue-property-decorator';
import Org from '@/models/Org';
import TextHighlight from 'vue-text-highlight';
import ApplicationBubble from '@/components/ApplicationBubble.vue';

@Component({ components: { TextHighlight, ApplicationBubble } })
export default class OrgSearchResult extends Vue {
    @Prop() data!: Org;

    @Prop() searchText!: string;

    @Prop(Boolean) showApplication!: boolean;

    handleClick () {
        return this.$emit('add-filter-id', this.data);
    }
}
