import { AxiosResponse } from 'axios';
import ApplicationType from '@/models/Application';
import Team from '@/models/Team';
import DbUserUpdate from '@/models/DbUserUpdate';
import ApiHelper from './apiHelper';

export const apiScope = {
    // TODO: constributor is a typo?
    constributor: [`api://${process.env.VUE_APP_AUTH_CLIENTID}/Contributor`],
};

export default class GraphApi {
    static async getApps (): Promise<unknown> {
        return ApiHelper.get('app', apiScope.constributor);
    }

    static async getUser (): Promise<unknown> {
        return ApiHelper.get('user', apiScope.constributor);
    }

    static async getUsers (): Promise<unknown> {
        return ApiHelper.get('users', apiScope.constributor);
    }

    static async getUserAccess (): Promise<unknown> {
        return ApiHelper.get('myaccess/orgInfo', apiScope.constributor);
    }

    static async getOrgsByName (payload: unknown): Promise<unknown> {
        return ApiHelper.post('Org/orgsbyname', payload, apiScope.constributor);
    }

    static async getOrgById (payload: { id: string }): Promise<unknown> {
        return ApiHelper.post('Org/orgbyid', payload, apiScope.constributor);
    }

    static async getD365ByName (payload: {Name: string}): Promise<unknown> { // unknown payload?
        return ApiHelper.post('D365/d365EntitiesByName', payload, apiScope.constributor);
    }

    static async updateOrgName (payload: {OrgId: string, OrgName: string}): Promise<unknown> {
        return ApiHelper.post('Org/updateOrgName', payload, apiScope.constributor);
    }

    static async updateD365ForOrg (payload: {OrgId: string, D365Id: string}): Promise<unknown> {
        return ApiHelper.post('D365/updateD365EntityForOrg', payload, apiScope.constributor);
    }

    static async getAssociatedUsersForOrg (payload: {OrgId: string}): Promise<unknown> {
        return ApiHelper.post('Org/getAssociatedUsersForOrg', payload, apiScope.constributor);
    }

    /**
   * Get all users associated with the given application
   * @param payload
   * @returns
   */
    static async getUserAssociations (payload: {
        OrgId: string;
    }): Promise<unknown> {
        return ApiHelper.post(
            'org/getUserAssociations',
            payload,
            apiScope.constributor,
        );
    }

    /**
   * Update Users & Apps association
   * @param payload
   * @returns
   */
    static async updateUserAssociations (payload: {
        OrgId: string;
        users: DbUserUpdate[];
    }): Promise<unknown> {
        return ApiHelper.post(
            'org/updateUserAssociations',
            payload,
            apiScope.constributor,
        );
    }

    static async checkChannelLink (payload: unknown): Promise<unknown> {
        return ApiHelper.post('team/channelByIds', payload, apiScope.constributor);
    }

    static async addNewOrg (newOrg: any): Promise<unknown> {
        return ApiHelper.post(
            'Org/createOrgHierarchy',
            newOrg,
            apiScope.constributor,
        );
    }

    static async updateOrgApps (
        orgId: string,
        appList: ApplicationType[],
    ): Promise<unknown> {
        return ApiHelper.post(
            'Org/updateApps',
            {
                orgId,
                apps: appList,
            },
            apiScope.constributor,
        );
    }

    static async updateOrgTeams (
        orgId: string,
        appList: Team[],
    ): Promise<AxiosResponse<any>> {
        const response = await ApiHelper.post('Org/updateTeams', { orgId, teams: appList }, apiScope.constributor);
        return response;
    }

    /**
   * Returns details (message for user) & result (seems to be success only atm)
   * @param user
   * @returns {Promise} response.data = { detail: string, result: string }
   */
    static async addUserToApplication (user: {
        Email: string;
        Firstname: string;
        Surname: string;
        AppId: string;
        OrgId: string;
    }): Promise<AxiosResponse<any>> {
        return ApiHelper.post('user/createUserAssoc', user, apiScope.constributor);
    }
}
