





























import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import TeamType from '@/models/Team';
import BaseButton from '@/components/base/BaseButton.vue';
import LoaderLogo from '@/components/LoaderLogo.vue';

@Component({
    components: {
        BaseButton, LoaderLogo,
    },
})
export default class EditTeamsSidebar extends Vue {
    @Prop() team!: TeamType;

    @Prop() index!: number;

    @Prop() notLastCompany!: boolean;

    @Prop() newTeamTeamAdId!: string;

    @Prop() isSuccessMessage!: boolean;

    @Prop() isErrorMessage!: boolean;

    @Prop() errorMessage!: string;

    @Prop() showTeamsUpdateErrorMessages!: boolean;

    public isNewTeam = this.newTeamTeamAdId === this.team.teamAdId;

    handleRemoveAssociation (): void {
        this.$emit('remove-team-association', this.index);
    }
}
