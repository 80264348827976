var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer" }, [
    _c("span", [
      _vm._v(
        " All content © copyright " +
          _vm._s(new Date().getFullYear()) +
          " Lane Clark & Peacock LLP. All rights reserved "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }