var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-type-wrapper" }, [
    _c(
      "h3",
      { staticClass: "user-type-name" },
      [
        _vm._v(" " + _vm._s(_vm.userName) + " "),
        _c("font-awesome-icon", {
          staticClass: "user-type-icon",
          attrs: { icon: ["fad", _vm.userIcon] },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "user-apps" },
      [
        _vm._l(_vm.apps, function (app, i) {
          return _c("AppBubble", { key: i, attrs: { app: app } })
        }),
        _c("ApplicationBubble"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }