var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-access-page" }, [
    _c(
      "div",
      { staticClass: "left" },
      [
        _c("FilterPanelAlt", {
          attrs: {
            urlQueries: _vm.urlQueries,
            numberOfPeople: _vm.combined.length,
          },
          on: { "update-filter-options": _vm.updateFilterOptions },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "right" }, [
      _c("div", { staticClass: "table-container" }, [
        _c(
          "div",
          { staticClass: "user-access-page--inner" },
          _vm._l(_vm.filteredApplications, function (application, l) {
            return _c("UserAccessAppTable", {
              key: "scheme-" + l,
              class: {
                hidden:
                  _vm.emptyAppTables.includes(application.id) ||
                  (!_vm.activeAppFilters.includes(application.id) &&
                    _vm.activeAppFilters.legnth > 0),
              },
              attrs: {
                application: application,
                query: _vm.inputValue,
                users: _vm.combined,
              },
              on: {
                areNoResults: _vm.addEmptyAppTable,
                areResults: _vm.removeEmptyAppTable,
              },
            })
          }),
          1
        ),
      ]),
      _vm.areNoResults || _vm.emptyAppTables.length === 4
        ? _c("div", { staticClass: "table-container" }, [
            _c(
              "div",
              { staticClass: "user-access-page--inner no-results" },
              [
                _vm._v(
                  " Could not find any results, please refine your search. "
                ),
                _c(
                  "font-awesome-layers",
                  { staticClass: "fa-6x no-results-icon" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fad", "list"] },
                    }),
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "slash"], transform: "left-2" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }