var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editAppsSidebar" },
    [
      _vm.sendingRequest
        ? _c("LoaderLogo")
        : [
            _c(
              "div",
              { staticClass: "header" },
              [
                _c("h2", [_vm._v("Edit App associations")]),
                _c(
                  "base-button",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: "Close",
                        expression: "'Close'",
                      },
                    ],
                    staticClass:
                      "search-wrapper-button btn-gradient condensed ml-auto btn-secondary",
                    attrs: { handleClick: _vm.forceClose },
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "icon icon-only",
                      attrs: { icon: ["fas", "times"] },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "body" }, [
              _c(
                "div",
                { staticClass: "appList" },
                _vm._l(_vm.applicationsList, function (application, index) {
                  return _c("Application", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.getAppTooltip(application),
                        expression: "getAppTooltip(application)",
                      },
                    ],
                    key: index,
                    staticClass: "application-body",
                    class: {
                      "application-disabled": !_vm.selectedAppsIds.includes(
                        application.id
                      ),
                    },
                    attrs: {
                      selected: _vm.selectedAppsIds.includes(application.id),
                      application: application,
                      showUserBubble: false,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.addSelectedApp($event, application)
                      },
                    },
                  })
                }),
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "actions" },
              [
                _c(
                  "BaseButton",
                  {
                    staticClass: "btn-gradient condensed inline",
                    attrs: {
                      handleClick: _vm.updateAppRequest,
                      disabled: _vm.sendingRequest,
                    },
                  },
                  [
                    _vm._v("Update "),
                    _c("font-awesome-icon", {
                      staticClass: "icon",
                      attrs: { icon: ["fad", "save"] },
                    }),
                  ],
                  1
                ),
                _c(
                  "BaseButton",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: "Deselect All Applications",
                        expression: "'Deselect All Applications'",
                      },
                    ],
                    staticClass: "btn-gradient condensed inline",
                    attrs: {
                      handleClick: _vm.showPopupToConfirm,
                      disabled:
                        _vm.sendingRequest || _vm.selectedAppsIds.length < 1,
                    },
                  },
                  [_vm._v("Deactivate Scheme ")]
                ),
              ],
              1
            ),
          ],
      _c("div", { class: { dark: _vm.showPopup } }),
      _c("transition", [
        _vm.showPopup
          ? _c("div", { staticClass: "popup" }, [
              _c("h3", [_vm._v("Important notice")]),
              _c("p", [
                _vm._v(
                  "Click 'Confirm' to deselect all selected applications and set the current scheme to inactive. After confirming, click \"Update\" to save the changes. If you decide not to proceed with deactivation, simply close the sidebar to cancel the changes."
                ),
              ]),
              _c("div", { staticClass: "btn-wrapper" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-gradient condensed inline",
                    on: { click: _vm.confirmDeactivate },
                  },
                  [_vm._v("Confirm")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn-gradient condensed inline",
                    on: { click: _vm.cancelDeactivate },
                  },
                  [_vm._v("Cancel")]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }