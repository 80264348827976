





































import { Component, Prop, Vue } from 'vue-property-decorator';
import Application from '@/components/Application.vue';
import ApplicationType from '@/models/Application';
import Org from '@/models/Org';

@Component({
    components: { Application },
})
export default class AddOrgApplications extends Vue {
    public appListSelection: string[] = [];

    public isDirty = false;

    public error = { message: '' };

    @Prop() currentStep!: number;

    @Prop() newOrg!: Org;

    created () {
        if (this.newOrg.isPensionScheme) {
            this.applicationsList.forEach((app) => {
                if (app.schemeFlag) {
                    this.appListSelection.push(app.id);
                }
            });
            this.$emit('updateOrg', this.appListSelection, 'applications');
        }
        if (this.appListSelection.length < 1) {
            this.$emit('updateValidity', false);
        }
    }

    get applicationsList (): ApplicationType[] {
        return this.$store.state.applications;
    }

    getAppTooltip (app: ApplicationType) : string {
        if (app.dependencyAppId === null) return '';

        return 'Selecting will add additional required apps.';
    }

    handleApplicationInOrg (app: ApplicationType) {
        const existingAppId = this.appListSelection.findIndex(
            (appId: string) => appId === app.id,
        );

        if (existingAppId !== -1) {
            Vue.delete(this.appListSelection, existingAppId);
            this.$emit('updateOrg', this.appListSelection, 'applications');
            return;
        }

        const dependantAppId = app.dependencyAppId;
        if (dependantAppId !== undefined && dependantAppId !== null) {
            this.appListSelection.push(dependantAppId);
        }

        this.appListSelection.push(app.id);
        this.isDirty = false;
        this.error.message = '';
        this.$emit('updateValidity', true);
        this.$emit('updateOrg', this.appListSelection, 'applications');
    }

    addApplicationValidation () {
        if (this.appListSelection.length === 0) {
            this.error.message = 'Please select at least 1 application';
            return this.error;
        }
        this.isDirty = true;
        if (this.isDirty && this.appListSelection.length > 0) {
            this.isDirty = false;
            this.error.message = '';
            return this.error;
        }
        return undefined;
    }
}
