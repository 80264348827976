























































import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import TextInput from '@/components/TextInput.vue';
import Teams from '@/models/Team';
import AddTeamsWithLink from '@/components/AddTeamsWithLink.vue';
import InstructionTeamLink from './InstructionTeamLink.vue';

@Component({
    components: {
        TextInput,
        AddTeamsWithLink,
        InstructionTeamLink,
    },
})
export default class AddTeamChannelAssociation extends Vue {
    public teamInputValue = '';

    public teamSelection: Teams[] = [];

    public error = { message: '' };

    public statusValid = false;

    public teamSearchResults = [];

    public handleLoading = false;

    @Prop() currentStep!: number;

    @Prop() newOrg!: any;

    created () {
        this.teamSelection = [...this.newOrg.teams];
        if (this.newOrg.teams.length < 1 || this.teamSelection.length < 1) {
            this.$emit('updateValidity', false);
        }
    }

    @Watch('newOrg', { immediate: true, deep: true })
    public checkTeamLinkValidity () {
        if (this.newOrg.teams.length < 1) {
            this.$emit('updateValidity', false);
            return;
        }
        this.$emit('updateValidity', true);
    }

    addTeamChannelAssociation (team: Teams) {
        this.teamInputValue = '';
        this.teamSelection.push(team);
        this.$emit('updateOrg', this.teamSelection, 'teams');
    }

    removeTeamAssociation (index: number) {
        Vue.delete(this.teamSelection, index);

        this.$emit('updateOrg', this.teamSelection, 'teams');
        if (this.teamSelection.length < 1) {
            this.$emit('updateValidity', false);
        }
    }

    get allTeams () {
        return this.$store.state.teams.filter((team: Teams) => !this.teamSelection.some((teamInList: Teams) => team.teamAdId === teamInList.teamAdId));
    }

    searchTeams () {
        this.teamSearchResults = this.$store.state.teams;
    }
}
