import store from '@/store';
import axios, { AxiosResponse } from 'axios';

export default class ApiHelper {
    static getToken (scopes: Array<string>): Promise<string | null> {
        return store.dispatch('auth/getToken', scopes);
    }

    static async get (
        url: string,
        scopes: Array<string>,
    ): Promise<AxiosResponse<unknown>> {
        const token: any = await ApiHelper.getToken(scopes);
        return axios.get(`${process.env.VUE_APP_API_ENDPOINT}/${url}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static async post (
        url: string,
        payload: unknown,
        scopes: Array<string>,
    ): Promise<AxiosResponse<unknown>> {
        const token: any = await ApiHelper.getToken(scopes);
        return axios.post(`${process.env.VUE_APP_API_ENDPOINT}/${url}`, payload, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
    }
}
