











import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class LoaderLogo extends Vue {
    @Prop(Boolean) condensed!: boolean;
}
