






























import { Component, Prop, Vue } from 'vue-property-decorator';
import SideBarUserSearch from '@/components/SideBarUserSearch.vue';
// import unixDateFormatter from "@/utils/unixDataformater";
import Team from '@/models/Team';

@Component({ components: { SideBarUserSearch } })
export default class TeamsCard extends Vue {
    @Prop() team!: Team;

    toggleSideBar () {
        this.$store.commit('toggleSideBar', !this.sideBarActive);
    }

    get sideBarActive () {
        return this.$store.state.ui.sideBar;
    }

    get abcUsers () {
        return this.$store.state.abcUsers;
    }

    get abcUsersAdmin () {
        return this.$store.state.abcUsersAdmin;
    }

    get sideBarUsers () {
        if (this.team.name === 'ABC Pension Org (admin)') {
            return this.abcUsersAdmin;
        }
        if (this.team.name === 'ABC Pension Org') {
            return this.abcUsersAdmin;
        }
        return null;
    }

    changeSideBarContent () {
        this.toggleSideBar();
    }
}
