var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chip", on: { click: _vm.toggle } },
    [
      _vm._v(" " + _vm._s(_vm.chipLabel ? _vm.chipLabel : "Chip") + " "),
      _c("font-awesome-icon", {
        staticClass: "icon",
        attrs: { icon: ["fas", "times"] },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }