









import { Component, Prop, Vue } from 'vue-property-decorator';
import Org from '@/models/Org';
import TextHighlight from 'vue-text-highlight';
import ApplicationBubble from '@/components/ApplicationBubble.vue';

@Component({ components: { TextHighlight, ApplicationBubble } })
export default class SearchResult extends Vue {
    @Prop() logo!: string;

    @Prop() orgData!: Org;

    @Prop() searchText!: string;

    @Prop(Boolean) showApplication!: boolean;

    addOrganisation () {
        return this.$emit('add-filter-id', this.orgData);
    }
}
