var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "side-bar-wrapper", class: { active: _vm.isSideBarActive } },
    [
      _c("div", {
        staticClass: "bg",
        class: { dull: _vm.isSideBarActive },
        on: { click: _vm.closeSideBar },
      }),
      _c(
        "div",
        { staticClass: "side-bar", class: _vm.sideBarName },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }