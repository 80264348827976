














import { Component, Vue } from 'vue-property-decorator';

@Component({ components: {} })
export default class SideBar extends Vue {
    get isSideBarActive () {
        return this.$store.state.ui.sideBar;
    }

    get sideBarName (): string {
        if (this.$store.getters.getPortalName === 'externalUsersMatrix') return 'lg';
        return '';
    }

    closeSideBar () {
        this.$store.dispatch('portalInView', '');
        this.$store.commit('toggleSideBar', false);
    }
}
