var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c("h2", { staticClass: "header-h2" }, [
          _vm._v("Team members "),
          !_vm.isRefreshDisabled
            ? _c("div", [
                _c("img", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value:
                        "Please wait 1-2 minutes before refresh after adding members in Teams, and up to 24 hours after deleting",
                      expression:
                        "'Please wait 1-2 minutes before refresh after adding members in Teams, and up to 24 hours after deleting'",
                    },
                  ],
                  staticClass: "rotate-icon",
                  attrs: {
                    src: "/svgs/refresh-icon-primary.svg",
                    alt: "refresh",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.refreshTeamsRequest()
                    },
                  },
                }),
              ])
            : _c("div", [
                _c("img", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value:
                        "Refresh Option Temporarily Disabled. Check back later if needed",
                      expression:
                        "'Refresh Option Temporarily Disabled. Check back later if needed'",
                    },
                  ],
                  staticClass: "rotate-icon rotate-icon-disabled",
                  attrs: {
                    src: "/svgs/refresh-icon-gray.svg",
                    alt: "refresh disabled",
                  },
                }),
              ]),
        ]),
        _c(
          "base-button",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: "Close",
                expression: "'Close'",
              },
            ],
            staticClass:
              "search-wrapper-button btn-gradient condensed ml-auto btn-secondary",
            attrs: { handleClick: _vm.forceClose },
          },
          [
            _c("font-awesome-icon", {
              staticClass: "icon icon-only",
              attrs: { icon: ["fas", "times"] },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "body" }, [
      _c(
        "div",
        { staticClass: "team-members" },
        [
          _vm.sendingRefreshRequest
            ? _c("LoaderLogo", { staticClass: "loader-logo" })
            : _vm._e(),
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _vm.loadingMemberResults
              ? _c("div", { staticClass: "loading" }, [
                  _c("img", { attrs: { src: "/svgs/circle-loader.svg" } }),
                ])
              : _vm._e(),
          ]),
          _vm.teamMembers && !_vm.loadingMemberResults
            ? _c(
                "div",
                [
                  _c("p", [
                    _vm._v(
                      "Search the Team Member by name or email or filter by team."
                    ),
                  ]),
                  _c("TextInput", {
                    attrs: { icon: "shield-alt" },
                    model: {
                      value: _vm.searchRequest,
                      callback: function ($$v) {
                        _vm.searchRequest = $$v
                      },
                      expression: "searchRequest",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "filter" },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "filter-item",
                          class: { active: _vm.activeTeam === "all" },
                          on: {
                            click: function ($event) {
                              return _vm.filterByTeamName("all")
                            },
                          },
                        },
                        [_vm._v("All teams")]
                      ),
                      _vm._l(_vm.listOfTeams, function (team, teamIndex) {
                        return _c(
                          "span",
                          {
                            key: teamIndex,
                            staticClass: "filter-item",
                            class: { active: _vm.activeTeam === team },
                            on: {
                              click: function ($event) {
                                return _vm.filterByTeamName(team)
                              },
                            },
                          },
                          [_vm._v(_vm._s(team))]
                        )
                      }),
                      _vm.unassociatedUsers
                        ? _c(
                            "span",
                            {
                              staticClass: "filter-item",
                              class: { active: _vm.activeTeam === "" },
                              on: {
                                click: function ($event) {
                                  return _vm.filterByTeamName("")
                                },
                              },
                            },
                            [_vm._v("No team association")]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm.memberResultsNotFound
                    ? _c("div", { staticClass: "no-items" }, [
                        _vm._v(" No Team Members found "),
                      ])
                    : _c(
                        "div",
                        { staticClass: "members" },
                        [
                          _c(
                            "transition-group",
                            {
                              staticClass: "member-cards",
                              attrs: { name: "list", tag: "ul" },
                            },
                            _vm._l(
                              _vm.searchedByNameTeamMembers,
                              function (member, memberIndex) {
                                return _c(
                                  "li",
                                  { key: memberIndex },
                                  [
                                    _c("TeamMembersSidebarCard", {
                                      attrs: { member: member },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ],
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }