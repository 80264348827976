var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "applications-inner" },
    _vm._l(_vm.allApplications, function (application, index) {
      return _c("Application", {
        key: index,
        staticClass: "application-body",
        attrs: {
          application: application,
          orgId: _vm.orgId,
          selectedAppId: _vm.selectedAppId,
        },
        on: {
          updateAppId: function ($event) {
            _vm.selectedAppId = $event
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }