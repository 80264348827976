








import { Component, Vue } from 'vue-property-decorator';
import Associations from '@/components/Associations.vue';

@Component({ components: { Associations } })
export default class AssosiationsPannel extends Vue {}
