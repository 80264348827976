var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "select-team" }, [
    _c("div", { staticClass: "add-org-team-association" }, [
      _c("div", { staticClass: "team-association-inner" }, [
        _c(
          "div",
          { staticClass: "org-heading" },
          [
            _c("h2", [_vm._v("Teams channel association")]),
            _c(
              "h3",
              [
                _c("font-awesome-icon", {
                  staticClass: "icon",
                  attrs: { icon: ["fa", "exclamation-triangle"] },
                }),
                _vm._v(
                  " Warning: Due to a change in Microsoft support, teams that have not been added to Authorise before will temporarily not be able to receive notifications"
                ),
              ],
              1
            ),
            _c("p", [
              _vm._v(" Select a "),
              _c("strong", [_vm._v("Channel Link")]),
              _vm._v(' to associate with "'),
              _c("strong", [_vm._v(_vm._s(_vm.newOrg.orgName))]),
              _vm._v('". '),
            ]),
            _c("p", [
              _vm._v(
                'This Teams association will provide "LCP authorise" with the list of individuals who will have access to this organisation\'s tools. This will update automatically when LCP people are added and removed from this Team.'
              ),
            ]),
            _c("p", [
              _vm._v(
                "Notifications for this organisation will be sent to the channel provided."
              ),
            ]),
            _c("InstructionTeamLink", { attrs: { withExplanation: false } }),
          ],
          1
        ),
        _vm.newOrg.teams.length === 0
          ? _c(
              "div",
              { staticClass: "teams-search" },
              [
                _c("AddTeamsWithLink", {
                  attrs: {
                    valueOverride: _vm.teamInputValue,
                    placeholder: "Please paste channel link here",
                  },
                  on: { "add-team-association": _vm.addTeamChannelAssociation },
                }),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "selected-teams" },
              _vm._l(_vm.newOrg.teams, function (team, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "teams-result-card" },
                  [
                    _vm._m(0, true),
                    _c("div", { staticClass: "teams-content" }, [
                      _c("span", { staticClass: "title" }, [
                        _vm._v(
                          " " +
                            _vm._s(team.name) +
                            " - " +
                            _vm._s(team.channelName) +
                            " "
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "icon-holder",
                        on: {
                          click: function ($event) {
                            return _vm.removeTeamAssociation(index)
                          },
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "icon",
                          attrs: { icon: ["fad", "trash-alt"] },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "teams-logo" }, [
      _c("img", { attrs: { src: "/svgs/microsoft-teams.svg" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }