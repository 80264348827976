








































































































































































































import {
    Component, Vue, Watch, Ref,
} from 'vue-property-decorator';
import { Route, NavigationGuardNext } from 'vue-router';
import MappingList from '@/components/MappingList.vue';
import TeamsCard from '@/components/TeamsCard.vue';
import Breadcrumbs from '@/components/Breadcrumb.vue';
import EditAppsSidebar from '@/components/EditAppsSidebar.vue';
import EditTeamsSidebar from '@/components/EditTeamsSidebar.vue';
import EditExternalUsersMatrixSidebar from '@/components/EditExternalUsersMatrixSidebar.vue';
import TextInput from '@/components/TextInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';
// import unixDateFormatter from "@/utils/unixDataformater";
// import * as dayjs from "dayjs";
import ApplicationGroup from '@/components/ApplicationGroup.vue';
import GraphApi from '@/api/graphApi';
import LoaderLogo from '@/components/LoaderLogo.vue';

import ApplicationType from '@/models/Application';
import EditD365Sidebar from '@/components/EditD365Sidebar.vue';
import TeamMembersSidebar from '@/components/TeamMembersSidebar.vue';
import UserBubble from '@/components/UserBubble.vue';

// import IOrgDetails from '@/models/OrgDetails';

Component.registerHooks(['beforeRouteEnter']);
@Component({
    components: {
        TeamsCard,
        MappingList,
        ApplicationGroup,
        Breadcrumbs,
        LoaderLogo,
        EditAppsSidebar,
        EditTeamsSidebar,
        EditExternalUsersMatrixSidebar,
        EditD365Sidebar,
        TeamMembersSidebar,
        UserBubble,
        TextInput,
        BaseButton,
    },
})
export default class OrgDetails extends Vue {
    public orgId = '';

    public orgName = '';

    public editAppList = false;

    public orgDetails: any = {};

    public orgLoaded = false;

    showUserBubble = true;

    public editingName = false;

    // public portalInView = "";

    async beforeRouteEnter (to: Route, from: Route, next: NavigationGuardNext) {
        const orgId: string = to.params.orgId;
        let orgResponse: any = {};
        if (orgId) {
            orgResponse = await GraphApi.getOrgById({ id: orgId });
            if (!orgResponse.data.org) {
                next('/');
            }
        } else {
            next('/');
        }
        next((vm: any) => {
            vm.orgId = orgId;
            vm.orgDetails = orgResponse.data;
            vm.orgLoaded = true;
            vm.orgName = orgResponse.data.org.name;
        });
    }

    created () {
        if (this.$route.query?.added === 'true') {
            this.$swal({
                title: 'New organisation successfully added',
                icon: 'success',
            });
        }
    }

    public showNameTextbox (): void {
        this.editingName = true;
    }

    public cancelNameUpdate (): void {
        this.editingName = false;
        this.orgName = this.orgDetails.org.name;
    }

    async saveName (): Promise<void> {
        const payload = {
            OrgId: this.orgDetails.org.id,
            OrgName: this.orgName,
        };
        const currentName = this.orgDetails.org.name;

        try {
            await GraphApi.updateOrgName(payload);
            this.orgDetails.org.name = this.orgName;
        } catch (e) {
            console.error(e);
            this.orgDetails.org.name = currentName;
            alert('New name could not be saved.');
        } finally {
            this.editingName = false;
        }
    }

    public showEditSidebar (viewToShow = 'editTeams'): void {
        this.$store.dispatch('portalInView', viewToShow);
        // this.portalInView = viewToShow;
        this.$store.commit('toggleSideBar', true);
    }

    public editEnableHandler (): void {
        this.editAppList = !this.editAppList;
    }

    get isInactive (): boolean {
        return this.orgDetails.apps.length < 1;
    }

    get linkAccountOrScheme (): string {
        return this.orgDetails.d365Entity.d365Type === 1 ? `https://lcpproduction.crm11.dynamics.com/main.aspx?appid=2f6f6c87-a4b1-eb11-8236-000d3a874bd8&forceUCI=1&newWindow=true&pagetype=entityrecord&etn=account&id=${this.orgDetails.d365Entity.d365Id}` : `https://lcpproduction.crm11.dynamics.com/main.aspx?appid=2f6f6c87-a4b1-eb11-8236-000d3a874bd8&pagetype=entityrecord&etn=ig_scheme&id=${this.orgDetails.d365Entity.d365Id}`;
    }

    get applicationsList (): ApplicationType[] {
        return this.$store.state.applications;
    }

    get sideBarOpen (): boolean {
    /* console.log('sideBarOpen'); */

        return this.$store.state.ui.sideBar;
    }

    get teamsDetails () {
        return this.orgDetails.teamApps;
    }

    // get orgSpecificApplications () {
    //     return []; // this.$store.getters.getApplications(this.orgDetails.applications);
    // }

    get editEnabled () {
        return this.$store.state.ui.editEnabled;
    }

    get applicationsById () {
        return this.orgDetails.apps.map((app: any) => this.$store.getters.getApplicationById(app.id));
    }

    // get allApplications (): ApplicationType[] {
    //     return this.editAppList
    //         ? this.applicationsList
    //         : this.orgSpecificApplications;
    // }

    get isLoggedIn () {
        return this.$store.state.isLoggedIn;
    }

    get portalInView () {
        return this.$store.state.ui.sideBarPortalName;
    }

    @Watch('sideBarOpen', { immediate: true })
    resetPortalContents (sideBarOpen: boolean): void {
    /* console.log('resetPortalContents');
 */
        if (!sideBarOpen) {
        // this.portalInView = "";
            this.$store.dispatch('portalInView', '');
            this.$store.commit('toggleSideBar', false);
        }
    }

    // #region Editing Apps
    @Ref('editAppsSidebar') readonly editAppsSidebar!: InstanceType<
    typeof EditAppsSidebar
    >;

    public async requestLatestData (defaultPortalInView = '', defaultToggleSideBar = false): Promise<void> {
        this.orgLoaded = false;
        // fetch latest data.
        const newOrgData: any = await GraphApi.getOrgById({
            id: this.orgId,
        });
        this.orgDetails = newOrgData.data;
        this.orgLoaded = true;

        this.$store.commit('toggleSideBar', defaultToggleSideBar);
        this.$store.dispatch('portalInView', defaultPortalInView);
        // timeout to allow refs to be re mounted before calling child method
        setTimeout(() => {
            // this.editAppsSidebar.forceClose();
            // this.portalInView = "";
        }, 1000);
    }
    // #endregion
}
