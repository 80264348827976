





































import { Component, Vue, Prop } from 'vue-property-decorator';
import Org from '@/models/Org';
// import unixDateFormatter from "@/utils/unixDataformater";
import ApplicationBubbleWrapper from '@/components/ApplicationBubbleWrapper.vue';

@Component({
    components: { ApplicationBubbleWrapper },
})
export default class DetailedOrgCard extends Vue {
    @Prop() org!: any;

    // get orgApplication() {
    //   return this.$store.getters.getApplications(this.org.applications);
    // }

    // findTeamNameById(id: string) {
    //   return this.$store.getters.getTeamById(id).title;
    // }
}
