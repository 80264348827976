var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "editAppsSidebar" }, [
    _vm._m(0),
    _c("div", { staticClass: "enter-entity-name" }, [
      _c(
        "div",
        { staticClass: "body" },
        [
          _vm._m(1),
          _c(
            "div",
            { staticClass: "entity-name-input" },
            [
              _c("TextInput", {
                attrs: { icon: "shield-alt" },
                on: {
                  keypress: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.checkForMatchingD365($event)
                  },
                },
                model: {
                  value: _vm.d365Name,
                  callback: function ($$v) {
                    _vm.d365Name = $$v
                  },
                  expression: "d365Name",
                },
              }),
              _c(
                "div",
                { staticClass: "btn-wrapper search" },
                [
                  _c(
                    "BaseButton",
                    {
                      staticClass: "btn-gradient condensed inline right",
                      attrs: {
                        handleClick: _vm.checkForMatchingD365,
                        disabled: _vm.loadingResults || _vm.d365Name.length < 3,
                      },
                    },
                    [_vm._v(" Search ")]
                  ),
                ],
                1
              ),
              _vm.resultsNotSearched
                ? _c("p", { staticClass: "error-message" }, [
                    _vm._v(
                      "We can't find a D365 entity which is similar to this search. Find out more about D365 "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "edit-link-underline",
                        attrs: {
                          href: "https://lcponline.sharepoint.com/sites/Strategyatlcp/SitePages/D365-Hub.aspx",
                          target: "_blank",
                        },
                      },
                      [_vm._v("here")]
                    ),
                    _vm._v("."),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _vm.loadingResults
              ? _c("div", { staticClass: "loading" }, [
                  _c("img", { attrs: { src: "/svgs/circle-loader.svg" } }),
                ])
              : _vm._e(),
          ]),
          _vm.resultsFound
            ? _c(
                "div",
                { staticClass: "search-field" },
                [
                  _c(
                    "transition",
                    { attrs: { name: "fade", mode: "out-in" } },
                    [
                      _c("div", { staticClass: "results-wrapper" }, [
                        _c("div", { staticClass: "results" }, [
                          _c("h2", { staticClass: "title" }, [
                            _vm._v("Here's what we found:"),
                          ]),
                          _vm.d365SearchResultsAvailable &&
                          _vm.d365SearchResultsAvailable.length > 0
                            ? _c("p", { staticClass: "results-p" }, [
                                _vm._v(
                                  " If your D365 entity is in the list below please click to add/edit it or search again. One D365 Entity can have only one Organisation associaton."
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "entity-result-wrapper" },
                            [
                              _vm._l(
                                _vm.d365SearchResultsAvailable,
                                function (d365Entity) {
                                  return _c("div", { key: d365Entity.d365Id }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "entity-result flex-space-between card",
                                        on: {
                                          click: function ($event) {
                                            return _vm.showPopupToConfirm(
                                              d365Entity
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(d365Entity.d365Name) +
                                            " "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "badge badge-active" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.d365TypeText(
                                                  d365Entity.d365Type
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                }
                              ),
                              _vm.d365SearchResultsNotAvailable &&
                              _vm.d365SearchResultsNotAvailable.length > 0
                                ? _c("p", { staticClass: "results-p" }, [
                                    _vm._v(
                                      "The following D365 Entities are already connected to organisations and are not available to be chosen."
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._l(
                                _vm.d365SearchResultsNotAvailable,
                                function (d365Entity) {
                                  return _c("div", { key: d365Entity.d365Id }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "entity-result flex-space-between card entity-result-not-active",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(d365Entity.d365Name) +
                                            " "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "badge badge-not-active",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.d365TypeText(
                                                  d365Entity.d365Type
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                }
                              ),
                            ],
                            2
                          ),
                          _c(
                            "div",
                            { staticClass: "btn-wrapper" },
                            [
                              _c(
                                "BaseButton",
                                {
                                  staticClass: "btn-gradient condensed inline",
                                  attrs: { handleClick: _vm.clearSearch },
                                },
                                [_vm._v("Clear search ")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("div", {
            class: { dark: _vm.showPopup || _vm.D365UpdateInProcess },
          }),
          _c("transition", [
            _vm.showPopup || _vm.D365UpdateInProcess
              ? _c(
                  "div",
                  { staticClass: "popup" },
                  [
                    _c("h3", [_vm._v("Important notice")]),
                    _vm.orgName !== _vm.currentD365.d365Name
                      ? _c("p", [
                          _vm._v("After some time the organisation name "),
                          _c("strong", [_vm._v(_vm._s(_vm.orgName))]),
                          _vm._v(" will be changed to "),
                          _c("strong", [
                            _vm._v(
                              '"' + _vm._s(_vm.currentD365.d365Name) + '"'
                            ),
                          ]),
                          _vm._v("."),
                        ])
                      : _c("p", [
                          _vm._v("The organisation name "),
                          _c("strong", [
                            _vm._v(
                              '"' + _vm._s(_vm.currentD365.d365Name) + '"'
                            ),
                          ]),
                          _vm._v(" will stay the same. "),
                        ]),
                    !_vm.D365UpdateInProcess
                      ? _c("div", { staticClass: "btn-wrapper" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn-gradient condensed inline",
                              on: { click: _vm.confirmD365Change },
                            },
                            [_vm._v("Confirm change")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn-gradient condensed inline",
                              on: { click: _vm.cancelD365Change },
                            },
                            [_vm._v("Cancel")]
                          ),
                        ])
                      : _vm._e(),
                    _c("transition", [
                      _vm.D365UpdateInProcess
                        ? _c("div", { staticClass: "loading popup-loading" }, [
                            _c("span", [_vm._v("Please, wait ")]),
                            _c("img", {
                              staticClass: "loading-img-small",
                              attrs: { src: "/svgs/circle-loader.svg" },
                            }),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("h2", [_vm._v("Edit D365 Entity")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "enter-entity-name-p" }, [
      _vm._v(
        " Please enter the D365 name and pick the corresponding Dynamics 365 entity. If the org is on D365 but does not appear, you may need to refresh the Authorise database. Find out more about D365 "
      ),
      _c(
        "a",
        {
          staticClass: "edit-link-underline",
          attrs: {
            href: "https://lcponline.sharepoint.com/sites/Strategyatlcp/SitePages/D365-Hub.aspx",
            target: "_blank",
          },
        },
        [_vm._v("here")]
      ),
      _vm._v(". "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }