




import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Applications extends Vue {
    get applications () {
        return this.$store.state.applications;
    }
}
