

















































import { Component, Vue } from 'vue-property-decorator';
import { Route, NavigationGuardNext } from 'vue-router';
import { VueMasonryPlugin } from 'vue-masonry';
import Application from '@/components/Application.vue';
import TeamsCard from '@/components/TeamsCard.vue';
import UserResult from '@/models/UserResult';
import Teams from '@/models/Team';
import Org from '@/models/Org';
import DetailedOrgCard from '@/components/DetailedOrgCard.vue';
import TextInput from '@/components/TextInput.vue';
import Breadcrumbs from '@/components/Breadcrumb.vue';
import GraphApi from '@/api/graphApi';
import LoaderLogo from '@/components/LoaderLogo.vue';
import DescriptionAuthorise from '@/components/DescriptionAuthorise.vue';

// import debounce from "lodash.debounce";
Vue.use(VueMasonryPlugin);
Component.registerHooks(['beforeRouteEnter']);

@Component({
    components: {
        Application,
        TeamsCard,
        DetailedOrgCard,
        TextInput,
        Breadcrumbs,
        LoaderLogo,
        DescriptionAuthorise,
    },
})
export default class User extends Vue {
    public inputValue = '';

    public dataLoaded = false;

    public usersOrgs = [];

    async beforeRouteEnter (to: Route, from: Route, next: NavigationGuardNext) {
        const userOrgsResponse: any = await GraphApi.getUserAccess();
        next((vm: any) => {
            vm.usersOrgs = userOrgsResponse.data;
            vm.dataLoaded = true;
        });
    }

    get userId () {
        return this.$route.params.userId || this.authUser.uniqueId;
    }

    get usersData () {
        return this.$store.state.abcUsersAdmin;
    }

    get authUser () {
        return this.$store.state.auth.authResponse;
    }

    get userData () {
        return this.usersData.find((elem: UserResult) => elem.id === this.userId);
    }

    get userApplications () {
        return this.$store.getters.getApplications(this.userData.applications);
    }

    get allTeams () {
        return this.$store.state.teams;
    }

    get userTeamsDetail () {
        return this.allTeams.filter(({ teamAdId }: Teams) => this.userData.teams.includes(teamAdId),
        );
    }

    get filteredOrgs () {
        return this.usersOrgs.filter((elem: any) => elem.org.name.toLowerCase().includes(this.inputValue.toLowerCase()),
        );
    }
}
