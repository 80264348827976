var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "applications-display",
      class: { chip: _vm.chip },
      on: {
        click: function ($event) {
          return _vm.applicationClickHandler(_vm.application.id)
        },
      },
    },
    [
      _c("div", { staticClass: "app-label" }, [
        _vm._v(" " + _vm._s(_vm.application.name) + " "),
      ]),
      _c(
        "div",
        { staticClass: "d-flex justify-content-between" },
        [
          _c("ApplicationBubble", {
            attrs: {
              application: _vm.application,
              chip: _vm.chip,
              selected: _vm.selected,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }