







import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class PermissionTag extends Vue {
    @Prop() permission!: string;

    get icon () {
        switch (this.permission) {
            case 'Read':
                return 'book';
            case 'PushLive':
                return 'upload';
            case 'Edit':
                return 'edit';
            default:
                return this.permission;
        }
    }
}
