


























import { Component, Vue } from 'vue-property-decorator';
import TextInput from '@/components/TextInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import Team from '@/models/Team';
import GraphApi from '@/api/graphApi';

@Component({ components: { TextInput, BaseButton }, inheritAttrs: false })
export default class AddTeamsWithLink extends Vue {
    public teamChannelUrl = '';

    public isFetchingData = false;

    public hasError = false;

    async searchTeams () {
    // const channelId = this.teamChannelUrl.trim().substring(38, 90);
        const splitBySlash = this.teamChannelUrl.trim().split('/');
        const channelId = splitBySlash[5];
        const channelIdDecoded = decodeURI(channelId);
        // get index of string but add 8 to account for the search string itself.
        const teamsIndex = this.teamChannelUrl.search('groupId=') + 8;
        const teamId = this.teamChannelUrl.substring(teamsIndex, teamsIndex + 36);

        if (this.isFetchingData) return;

        this.isFetchingData = true;
        const payload = {
            channel: channelIdDecoded,
            id: teamId,
        };
        /*  console.log(payload); */

        try {
            const result: any = await GraphApi.checkChannelLink(payload);
            const newTeam = new Team(
                result.data.id,
                result.data.name,
                result.data.teamAdId,
                result.data.channel,
                result.data.channelName,
                result.data.channelEmailAddress,
            );
            this.isFetchingData = false;
            this.hasError = false;
            this.$emit('add-team-association', newTeam);
            this.teamChannelUrl = '';
            /* console.log(result);
            console.log(newTeam); */
        } catch (e) {
            console.error(e);
            this.teamChannelUrl = '';
            this.hasError = true;
            this.isFetchingData = false;
        }
    }
}
