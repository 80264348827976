var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "checkbox-wrapper",
      on: {
        click: function ($event) {
          return _vm.handleSelect()
        },
      },
    },
    [
      _c("font-awesome-icon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeCheckbox.includes(_vm.checkboxData.id),
            expression: "activeCheckbox.includes(checkboxData.id)",
          },
        ],
        staticClass: "checkbox",
        attrs: { icon: ["fas", "check-square"] },
      }),
      _c("font-awesome-icon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.activeCheckbox.includes(_vm.checkboxData.id),
            expression: "!activeCheckbox.includes(checkboxData.id)",
          },
        ],
        staticClass: "checkbox",
        attrs: { icon: ["far", "square"] },
      }),
      _vm._v(" " + _vm._s(_vm.checkboxData.label) + " "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }