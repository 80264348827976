



















import { Component, Vue, Prop } from 'vue-property-decorator';
import ApplicationBubble from '@/components/ApplicationBubble.vue';
import UserBubble from '@/components/UserBubble.vue';
import Applications from '@/models/Application';

@Component({ components: { ApplicationBubble, UserBubble } })
export default class Application extends Vue {
    @Prop() application!: Applications;

    @Prop() orgId!: string;

    @Prop(Boolean) useAsFilter?: boolean;

    @Prop(Boolean) chip?: boolean;

    @Prop(Boolean) selected!: boolean;

    @Prop({ default: true }) showUserBubble!: boolean;

    @Prop() selectedAppId?: string;

    public showEditSidebar (viewToShow: string): void {
        this.$store.dispatch('portalInView', viewToShow);
        this.$store.commit('toggleSideBar', true);
    }

    applicationClickHandler (id: string) {
        if (this.editEnabled || this.useAsFilter) {
            this.applicationListEdit(id);
        } else {
            this.goToOrgWithApp();
        }
    }

    applicationListEdit (id: string) {
        this.$emit('applicationClickHandler', id);
    }

    goToOrgWithApp () {
        this.$router.push({
            path: `/org/${this.orgId}/app/${this.application.id}`,
        });
    }

    get editEnabled () {
        return this.$store.state.ui.editEnabled;
    }

    get portalInView () {
        return this.$store.state.ui.sideBarPortalName;
    }
}
