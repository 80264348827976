
































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
    // toggleSideBar() {
    //   let isSideBarActive = this.sideBarActive;
    //   isSideBarActive = !isSideBarActive;
    //   this.$store.commit("toggleSideBar", isSideBarActive);
    // }
    // get sideBarActive() {
    //   return this.$store.state.ui.sideBar;
    // }
}
