










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class AppBubble extends Vue {
    @Prop() app!: string;

    get bubbleAttr () {
        switch (this.app) {
            case 'PWM':
                return { color: '#e93f6f', label: 'Pulse work manager' };
            case 'Vis':
                return { color: '#002f5f', label: 'Visualise' };
            case 'PCW':
                return { color: '#00b0cf', label: 'Pulse client web' };
            case 'Mon':
                return { color: '#fcb64c', label: 'Monitor' };
            default:
                return { color: '#ededed', label: '' };
        }
    }
}
