


































import { Component, Prop, Vue } from 'vue-property-decorator';
import UserTableRow from '@/components/UserTableRow.vue';
import UserResult from '@/models/UserResult';
import Application from '@/models/Application';
import ApplicationBubble from '@/components/ApplicationBubble.vue';

@Component({ components: { UserTableRow, ApplicationBubble } })
export default class UserAccessApplication extends Vue {
    @Prop() application!: Application;

    @Prop() query!: string;

    @Prop() filteredOrgDetails!: string[];

    get users () {
        return this.$store.state.abcUsersAdmin;
    }

    get applicationUsers () {
        return this.users.filter((elem: UserResult) => elem.applications.includes(this.application.id),
        );
    }

    get filteredApplicationUsers () {
        if (this.query) {
            return this.applicationUsers.filter((elem: UserResult) => elem.name.toLowerCase().includes(this.query.toLowerCase()),
            );
        }
        return this.applicationUsers;
    }

    get filteredByOrg () {
        if (this.filteredOrgDetails.length > 0) {
            return this.applicationUsers.filter(
                (elem: UserResult) => !elem.org.some((ele) => this.filteredOrgDetails.includes(ele)),
            );
        }
        return this.applicationUsers;
    }

    get combinedFilters () {
        const allFilteredResults = [
            ...this.filteredApplicationUsers,
            ...this.filteredByOrg,
        ];
        if (allFilteredResults.length === 0) {
            this.$emit('areNoResults', this.application.id);
        } else {
            this.$emit('areResults', this.application.id);
        }
        return allFilteredResults;
    }
}
