var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "add-org" }, [
      _c("div", { staticClass: "add-org-inner" }, [
        _c(
          "div",
          { staticClass: "wizard" },
          [
            _c("WizardTabs", {
              attrs: { currentStep: _vm.currentStep, steps: _vm.stepTitles },
            }),
            _c(_vm.dynamicWizardComponent.componentName, {
              key: _vm.dynamicWizardComponent.componentName,
              tag: "component",
              staticClass: "steps",
              attrs: { newOrg: _vm.newOrg, showLoader: _vm.showLoader },
              on: {
                updateValidity: _vm.setValidity,
                updateOrg: _vm.updateOrgData,
                jumpToStep: _vm.jumpToStep,
              },
            }),
            _vm.dynamicWizardComponent.showStepButtons && !_vm.showLoader
              ? _c(
                  "div",
                  {
                    staticClass: "next-wizard",
                    class: { "show-previous": _vm.currentStep > 0 },
                  },
                  [
                    _vm.currentStep > 0
                      ? _c(
                          "base-button",
                          {
                            staticClass: "btn save-btn btn-gradient condensed",
                            attrs: { handleClick: _vm.previous },
                          },
                          [_vm._v(" Previous ")]
                        )
                      : _vm._e(),
                    _c(
                      "base-button",
                      {
                        staticClass: "btn save-btn btn-gradient condensed",
                        attrs: {
                          disabled: !_vm.statusIsValid,
                          handleClick: _vm.next,
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }