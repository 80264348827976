var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.dataLoaded
          ? _c(
              "div",
              { key: "loaded" },
              [
                _vm.loggedIn
                  ? [
                      _vm.showHeader ? _c("Header") : _vm._e(),
                      _c(
                        "div",
                        [
                          _c(
                            "SideBar",
                            [
                              _c("portal-target", {
                                attrs: { name: "sidebar" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "pageWrapper" },
                            [_c("router-view")],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.showFooter ? _c("Footer") : _vm._e(),
                    ]
                  : [_c("login")],
              ],
              2
            )
          : _vm._e(),
        !_vm.dataLoaded
          ? _c(
              "div",
              { key: "notLoaded", staticClass: "loader-wrapper" },
              [_c("LoaderLogo")],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }