




























































import { Component, Prop, Vue } from 'vue-property-decorator';
import Application from '@/components/Application.vue';
import ApplicationType from '@/models/Application';
import Org from '@/models/Org';
import LoaderLogo from '@/components/LoaderLogo.vue';

@Component({
    components: { Application, LoaderLogo },
})
export default class AddOrgSummary extends Vue {
    @Prop() currentStep!: number;

    @Prop() newOrg!: Org;

    @Prop() showLoader!: boolean;

    created () {
        this.$emit('updateValidity', true);
    }

    get applicationsList (): ApplicationType[] {
        return this.$store.state.applications;
    }

    public getApplicationById (appId: string) {
        return this.applicationsList.find((app) => app.id === appId);
    }
}
