





































import EditUser from '@/models/EditUser';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ExternalUserWithDelete extends Vue {
    @Prop() user!: EditUser;

    public showConfirmDelete = false;

    public toggleConfirmationDelete () {
        this.showConfirmDelete = !this.showConfirmDelete;
    }

    // TODO: awaiting API for delete
    public deleteUserAssociation () {
        alert('awaiting API');
    }
}
