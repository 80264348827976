














import { Component, Prop, Vue } from 'vue-property-decorator';
import Application from '@/components/Application.vue';

import ApplicationType from '@/models/Application';

@Component({
    components: {
        Application,
    },
})
export default class ApplicationGroup extends Vue {
    @Prop() allApplications!: ApplicationType[];

    @Prop() orgId!: string;

    public selectedAppId = '';
}
