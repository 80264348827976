var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c(
      "div",
      {
        staticClass: "card-body",
        style: {
          borderLeft: _vm.application.color + " 0.5rem solid",
          borderRadius: "0.5rem",
        },
      },
      [
        _c(
          "div",
          { staticClass: "title-wrapper" },
          [
            _c("ApplicationBubble", {
              attrs: { large: "", application: _vm.application },
            }),
            _c("h3", { staticClass: "card-title" }, [
              _vm._v(_vm._s(_vm.application.label)),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "table-conatainer" }, [
          _c("div", { staticClass: "table" }, [
            _c(
              "div",
              { staticClass: "table-body" },
              [
                _vm._m(0),
                _vm._l(_vm.combinedFilters, function (user, i) {
                  return _c("UserTableRow", {
                    key: i,
                    attrs: { userDetails: user, query: _vm.query },
                  })
                }),
              ],
              2
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tr" }, [
      _c("div", { staticClass: "td headers user" }, [_vm._v("User")]),
      _c("div", { staticClass: "td headers org" }, [_vm._v("Org")]),
      _c("div", { staticClass: "td headers user-access" }, [
        _vm._v("User Access"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }