










































import { Component, Vue } from 'vue-property-decorator';
import AssociationsPannel from '@/components/AssociationsPannel.vue';
import Button from '@/components/Button.vue';
import Multiselect from 'vue-multiselect';

@Component({ components: { Button, AssociationsPannel, Multiselect } })
export default class OrgApp extends Vue {
    public headers: string[] = ['EMAIL', 'USER TYPE'];

    public emailUserTypes = [
        { email: 'asdkl@asd', userType: 'admin' },
        { email: 'asdkl@asd', userType: 'admin' },
        { email: 'asdkl@asd', userType: 'admin' },
    ];

    public options: string[] = ['ABC_Pension_Fund', 'DEF_Pension_Fund'];

    public selectedOption = '';
}
