
















import { Component, Vue, Prop } from 'vue-property-decorator';
import Application from '@/models/Application';

@Component
export default class Checkbox extends Vue {
    @Prop() checkboxData!: Application;

    @Prop() activeCheckbox!: string[];

    selected = false;

    handleSelect () {
        this.selected = !this.selected;
        this.$emit('select-handler', this.checkboxData.id);
    }
}
