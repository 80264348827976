import { render, staticRenderFns } from "./DetailedOrgCard.vue?vue&type=template&id=f42f9d78&scoped=true&"
import script from "./DetailedOrgCard.vue?vue&type=script&lang=ts&"
export * from "./DetailedOrgCard.vue?vue&type=script&lang=ts&"
import style0 from "./DetailedOrgCard.vue?vue&type=style&index=0&id=f42f9d78&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f42f9d78",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/authorise-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f42f9d78')) {
      api.createRecord('f42f9d78', component.options)
    } else {
      api.reload('f42f9d78', component.options)
    }
    module.hot.accept("./DetailedOrgCard.vue?vue&type=template&id=f42f9d78&scoped=true&", function () {
      api.rerender('f42f9d78', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/DetailedOrgCard.vue"
export default component.exports