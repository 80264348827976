































import { Component, Prop, Vue } from 'vue-property-decorator';
import UserResult from '@/models/UserResult';
import TextHighlight from 'vue-text-highlight';
import ApplicationBubble from '@/components/ApplicationBubble.vue';

@Component({ components: { TextHighlight, ApplicationBubble } })
export default class UserSearchResult extends Vue {
    @Prop() data!: UserResult;

    @Prop() searchText!: string;

    @Prop(Boolean) showApplication!: boolean;

    get orgApplication () {
        return this.$store.getters.getApplications(this.data.applications);
    }

    handleClick () {
        this.$emit('handle-click', this.data);
    }
}
