var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-search-result", on: { click: _vm.handleClick } },
    [
      _vm.searchText
        ? _c(
            "div",
            { staticClass: "left-wrapper" },
            [
              _c(
                "text-highlight",
                {
                  staticClass: "user-name",
                  attrs: {
                    queries: _vm.searchText,
                    highlightClass: "highlighted-text",
                  },
                },
                [_vm._v(_vm._s(_vm.data.name))]
              ),
              _c(
                "text-highlight",
                {
                  staticClass: "user-email",
                  attrs: {
                    queries: _vm.searchText,
                    highlightClass: "highlighted-text",
                  },
                },
                [_vm._v(_vm._s(_vm.data.email))]
              ),
            ],
            1
          )
        : _c("div", { staticClass: "left-wrapper" }, [
            _c("div", { staticClass: "user-name" }, [
              _vm._v(_vm._s(_vm.data.name)),
            ]),
            _c("div", { staticClass: "user-email" }, [
              _vm._v(_vm._s(_vm.data.email)),
            ]),
          ]),
      _vm.showApplication
        ? _c(
            "div",
            { staticClass: "user-apps" },
            _vm._l(_vm.orgApplication, function (application, index) {
              return _c("ApplicationBubble", {
                key: index,
                attrs: { application: application, small: "" },
              })
            }),
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }