














































import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { AxiosResponse } from 'axios';
import TeamType from '@/models/Team';
import TeamsUpdateResponse from '@/models/TeamsUpdateResponse';
import BaseButton from '@/components/base/BaseButton.vue';
import LoaderLogo from '@/components/LoaderLogo.vue';
import AddTeamsWithLink from '@/components/AddTeamsWithLink.vue';
import EditTeamsSidebarTeamCard from '@/components/EditTeamsSidebarTeamCard.vue';
import InstructionTeamLink from '@/components/InstructionTeamLink.vue';
import GraphApi from '@/api/graphApi';

@Component({
    components: {
        BaseButton, LoaderLogo, AddTeamsWithLink, EditTeamsSidebarTeamCard, InstructionTeamLink,
    },
})
export default class EditTeamsSidebar extends Vue {
    @Prop() orgTeamList!: TeamType[];

    @Prop() orgId!: string;

    @Prop(Boolean) showLoading!: boolean;

    public teamListSelection: TeamType[] = [];

    public teamInputValue = '';

    public newTeamTeamAdId = '';

    public sendingRequest = false;

    public notLastCompany = false;

    public teamAlreadyExists = false;

    public teamsUpdateResponse: TeamsUpdateResponse | null = null;

    public showTeamsUpdateErrorMessages = false;

    mounted () {
        this.teamListSelection = [...this.orgTeamList];
    }

    @Watch('teamListSelection', { immediate: true, deep: true })
    public ifLastCompany () {
        this.notLastCompany = this.teamListSelection.length > 1;
    }

    public async addTeamAssociation (team: TeamType): Promise<void> {
        this.newTeamTeamAdId = team.teamAdId;
        this.teamInputValue = '';
        this.teamAlreadyExists = this.teamListSelection.some((listTeam) => listTeam.teamAdId === team.teamAdId);
        if (!this.teamAlreadyExists) {
            this.teamListSelection.push(team);
            this.$emit('updateOrg', this.teamListSelection, 'teams');
        }
    }

    public removeTeamAssociation (index: number) {
        Vue.delete(this.teamListSelection, index);
        this.$emit('updateOrg', this.teamListSelection, 'teams');
    }

    public async updateTeamsRequest (): Promise<void> {
        this.sendingRequest = true;

        try {
            const response: AxiosResponse<TeamsUpdateResponse> = await GraphApi.updateOrgTeams(
                this.orgId,
                this.teamListSelection,
            );
            this.teamsUpdateResponse = JSON.parse(JSON.stringify(response.data));
            if (this.teamsUpdateResponse && this.teamsUpdateResponse.adds.failures.length === 0 && this.teamsUpdateResponse.deletes.failures.length === 0 && this.teamsUpdateResponse.updates.failures.length === 0) {
                this.showTeamsUpdateErrorMessages = false;
                this.$emit('requestLatestData');
                return;
            }
            this.showTeamsUpdateErrorMessages = true;
        } catch (error) {
            console.error('Error updating teams:', error);
        // Handle the error appropriately
        } finally {
            this.sendingRequest = false;
        }
    }

    private isTeamInList (team: TeamType, teamsList: Array<TeamType>): boolean {
        return teamsList.some((statusTeam) => statusTeam.id === team.id);
    }

    public getTeamStatusMessage (team: TeamType) {
        const stringifiedTeamsUpdateResponse = JSON.parse(JSON.stringify(this.teamsUpdateResponse));
        if (!stringifiedTeamsUpdateResponse) return '';

        if (this.isTeamInList(team, stringifiedTeamsUpdateResponse.adds.successes)) {
            return 'Successfully added';
        }
        if (this.isTeamInList(team, stringifiedTeamsUpdateResponse.updates.successes)) {
            return 'Successfully updated';
        }
        if (this.isTeamInList(team, stringifiedTeamsUpdateResponse.deletes.successes)) {
            return 'Successfully deleted';
        }
        if (this.isTeamInList(team, stringifiedTeamsUpdateResponse.adds.failures)) {
            return 'Error: Unable to add this Team. Please double-check that the Team and its links in Teams are correct. If the problem persists, contact support.';
        }
        if (this.isTeamInList(team, stringifiedTeamsUpdateResponse.updates.failures)) {
            return 'Error: Team update failed. This Team may have been deleted in Teams. If so, please remove it from Authorise as well. For further issues, contact support.';
        }
        if (this.isTeamInList(team, stringifiedTeamsUpdateResponse.deletes.failures)) {
            return 'Error: Unable to delete this Team. Please contact support.';
        }
        return '';
    }

    public isSuccessMessage (team: TeamType) {
        if (!this.teamsUpdateResponse) return '';
        const stringifiedTeamsUpdateResponse = JSON.parse(JSON.stringify(this.teamsUpdateResponse));
        return stringifiedTeamsUpdateResponse.adds.successes.some((statusTeam: TeamType) => statusTeam.id === team.id)
           || stringifiedTeamsUpdateResponse.updates.successes.some((statusTeam: TeamType) => statusTeam.id === team.id)
           || stringifiedTeamsUpdateResponse.deletes.successes.some((statusTeam: TeamType) => statusTeam.id === team.id);
    }

    public isErrorMessage (team: TeamType) {
        if (!this.teamsUpdateResponse) return '';
        const stringifiedTeamsUpdateResponse = JSON.parse(JSON.stringify(this.teamsUpdateResponse));
        return stringifiedTeamsUpdateResponse.adds.failures.some((statusTeam: TeamType) => statusTeam.id === team.id)
           || stringifiedTeamsUpdateResponse.updates.failures.some((statusTeam: TeamType) => statusTeam.id === team.id)
           || stringifiedTeamsUpdateResponse.deletes.failures.some((statusTeam: TeamType) => statusTeam.id === team.id);
    }

    public forceClose (): void {
        this.$store.commit('toggleSideBar', false);
        this.$store.dispatch('portalInView', '');
        this.showLoading = false;
    }

    destroyed () {
        if (this.showTeamsUpdateErrorMessages) {
            this.$emit('requestLatestData');
        }
    }
}

