var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.bubbleAttr.label,
          expression: "bubbleAttr.label",
        },
      ],
      staticClass: "app-bubble",
      style: { backgroundColor: _vm.bubbleAttr.color },
    },
    [_vm._v(" " + _vm._s(_vm.app) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }