var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "add-application" }, [
    _c("div", { staticClass: "add-org-applications" }, [
      _c("div", { staticClass: "add-applications-inner" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "add-applications-content" },
          _vm._l(_vm.applicationsList, function (application, index) {
            return _c("Application", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.getAppTooltip(application),
                  expression: "getAppTooltip(application)",
                },
              ],
              key: index,
              staticClass: "application-body",
              class: {
                "application-disabled": !_vm.appListSelection.includes(
                  application.id
                ),
              },
              attrs: {
                selected: _vm.appListSelection.includes(application.id),
                application: application,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleApplicationInOrg(application)
                },
              },
            })
          }),
          1
        ),
        _c("div", { staticClass: "notice" }, [
          _vm.error.message
            ? _c("div", { staticClass: "error" }, [
                _vm._v(" " + _vm._s(_vm.error.message) + " "),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "org-heading" }, [
      _c("h2", [_vm._v("App list")]),
      _c("p", [
        _vm._v(
          " Select apps from the list below for which you want this organisation to have access to "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }