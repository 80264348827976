var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "loader", class: { condensed: _vm.condensed } },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("img", { attrs: { src: "/svgs/lcp-authorise-logo-new.svg" } }),
      _c("div", { staticClass: "linear-activity" }, [
        _c("div", { staticClass: "indeterminate" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }