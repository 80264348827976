


























// eslint-disable-next-line no-irregular-whitespace
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Login extends Vue {
    login (): Promise<void> {
        return this.$store.dispatch('auth/login').then(() => {
            if (this.$route.query.url) {
                window.location.href = this.$route.query.url as string;
            }
            this.$nextTick(() => {
                this.$store.dispatch('auth/getToken');
            });
        });
    }

    logout (): void {
        this.$store.dispatch('auth/logout').then(() => {
            this.$store.dispatch('auth/getToken');
        });
    }
}
