











import { Component, Vue, Prop } from 'vue-property-decorator';
import OrgType from '@/models/Org';

@Component({ components: {} })
export default class Org extends Vue {
    @Prop() org!: OrgType;

    @Prop(Boolean) chip?: false;

    @Prop(Boolean) selectedFilter?: false;
}
