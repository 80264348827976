var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "users-result-card" }, [
    _c(
      "div",
      { staticClass: "user-details-wrapper d-flex align-items-center" },
      [
        _c("div", { staticClass: "users-content d-flex align-items-center" }, [
          _c(
            "span",
            { staticClass: "p-3" },
            [
              _c("font-awesome-icon", {
                staticClass: "icon mr-3 ml-0",
                attrs: { icon: ["fas", "user"] },
              }),
            ],
            1
          ),
          _c("div", [
            _c("span", { staticClass: "user-name" }, [
              _vm._v(
                _vm._s(_vm.user.firstName) + " " + _vm._s(_vm.user.lastName)
              ),
            ]),
            _c("br"),
            _c("span", { staticClass: "user-name" }, [
              _vm._v(_vm._s(_vm.user.email)),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: "delete",
                expression: "`delete`",
              },
            ],
            staticClass: "icon-holder p-3",
            on: { click: _vm.toggleConfirmationDelete },
          },
          [
            _c("font-awesome-icon", {
              staticClass: "icon",
              attrs: { icon: ["fad", "trash-alt"] },
            }),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "action-slider d-flex",
        class: { show: _vm.showConfirmDelete },
      },
      [
        _c("span", [_vm._v("confirm delete")]),
        _c("div", { staticClass: "actions" }, [
          _c(
            "button",
            {
              staticClass: "action-btn cancel",
              on: { click: _vm.toggleConfirmationDelete },
            },
            [_vm._v(" cancel ")]
          ),
          _c(
            "button",
            {
              staticClass: "action-btn delete",
              on: { click: _vm.deleteUserAssociation },
            },
            [_vm._v(" delete ")]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }