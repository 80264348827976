







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Chip extends Vue {
    @Prop() chipId!: string;

    @Prop() chipLabel!: string;

    selected = true;

    toggle () {
        this.selected = false;
        this.$emit('orgClickHandler', this.chipId);
    }
}
