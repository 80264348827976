var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "enter-org-name" },
    [
      _vm._t("default"),
      _c(
        "div",
        { staticClass: "org-heading" },
        [
          _vm.searchOrg
            ? [
                _c("h2", [_vm._v("Add organisation")]),
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "org-name-input" },
                  [
                    _c("TextInput", {
                      attrs: { icon: "shield-alt" },
                      on: {
                        keypress: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.checkForConflicitingOrg($event)
                        },
                      },
                      model: {
                        value: _vm.orgName,
                        callback: function ($$v) {
                          _vm.orgName = $$v
                        },
                        expression: "orgName",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "btn-wrapper search" },
                      [
                        _c(
                          "BaseButton",
                          {
                            staticClass: "btn-gradient condensed inline right",
                            attrs: {
                              handleClick: _vm.checkForConflicitingOrg,
                              disabled:
                                _vm.loadingResults || _vm.orgName.length < 3,
                            },
                          },
                          [_vm._v("Continue ")]
                        ),
                      ],
                      1
                    ),
                    _vm.resultsNotFound
                      ? _c("p", { staticClass: "error-message" }, [
                          _vm._v(
                            "We can't find an organisation which is similar to this search. You will need to set it up on D365 first. Find out more about D365 "
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "edit-link-underline",
                              attrs: {
                                href: "https://lcponline.sharepoint.com/sites/Strategyatlcp/SitePages/D365-Hub.aspx",
                                target: "_blank",
                              },
                            },
                            [_vm._v("here")]
                          ),
                          _vm._v("."),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
                  _vm.loadingResults
                    ? _c("div", { staticClass: "loading" }, [
                        _c("img", {
                          attrs: { src: "/svgs/circle-loader.svg" },
                        }),
                      ])
                    : _vm._e(),
                ]),
              ]
            : _vm._e(),
          _vm.resultsFound
            ? [
                _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
                  _c("div", { staticClass: "results-wrapper" }, [
                    _c(
                      "div",
                      { staticClass: "results" },
                      [
                        _c("h2", { staticClass: "title" }, [
                          _vm._v("Here's what we found:"),
                        ]),
                        _c("p", [
                          _vm._v(
                            " If your organisation is in the list below please click to add/edit it, or got back to search again. Those marked 'D365' have yet to be added to authorise. Picking one of these will take you to the next stage of the setup. Those marked 'Authorise' already have an authorise association. Picking one of these will take you to that setup. "
                          ),
                          _c("strong", [
                            _vm._v(
                              "Organisations that are added to LCP Authorise are sorted to the top of the list. Please check first to see if the organisation already exists."
                            ),
                          ]),
                        ]),
                        _vm._l(_vm.orgNameSearchResults, function (org, index) {
                          return _c(
                            "div",
                            {
                              key: org.id,
                              class: {
                                divider:
                                  (index !== 0 &&
                                    _vm.firstOfAccountTypeIndex === index) ||
                                  (index !== 0 &&
                                    _vm.firstOfSchemeTypeIndex === index),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "orgResult flex-space-between card",
                                  class: { notAuthorise: org.orgType !== 0 },
                                  on: {
                                    click: function ($event) {
                                      return _vm.routeToOrg(org)
                                    },
                                  },
                                },
                                [
                                  _vm._v(" " + _vm._s(org.name) + " "),
                                  _c("span", { staticClass: "badge" }, [
                                    _vm._v(
                                      _vm._s(_vm.orgTypeText(org.orgType))
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "edit" },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "icon",
                                        attrs: {
                                          icon: ["fal", "chevron-right"],
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        _c(
                          "div",
                          { staticClass: "btn-wrapper" },
                          [
                            _c(
                              "BaseButton",
                              {
                                staticClass: "btn-gradient condensed inline",
                                attrs: { handleClick: _vm.clearSearch },
                              },
                              [
                                _vm._v("Return to search "),
                                _c("font-awesome-icon", {
                                  staticClass: "icon",
                                  attrs: { icon: ["fal", "chevron-left"] },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]
            : _vm._e(),
          _vm.orgAlreadyChosen
            ? [
                _c("h2", [_vm._v("Edit organisation name")]),
                _c("p", [
                  _vm._v(
                    "Please click the edit icon to change your organisation name"
                  ),
                ]),
                _c("div", { staticClass: "org-name-input" }, [
                  _c(
                    "div",
                    {
                      staticClass: "scheme-name-display",
                      on: { click: _vm.editSchemeName },
                    },
                    [
                      _c("div", { staticClass: "scheme-name" }, [
                        _vm._v(_vm._s(_vm.newOrg.orgName)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "edit-icon" },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "icon",
                            attrs: { icon: ["fad", "edit"] },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "btn-wrapper next right" },
                  [
                    _c(
                      "BaseButton",
                      {
                        staticClass: "btn-gradient condensed inline",
                        attrs: { handleClick: _vm.skipStep },
                      },
                      [
                        _vm._v("Next "),
                        _c("font-awesome-icon", {
                          staticClass: "icon",
                          attrs: { icon: ["fal", "chevron-right"] },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " Please enter the organisation name and pick the corresponding Dynamics 365 entity. If you can't find your organisation it will need to be set up on D365 first. Find out more about D365 "
      ),
      _c(
        "a",
        {
          staticClass: "edit-link-underline",
          attrs: {
            href: "https://lcponline.sharepoint.com/sites/Strategyatlcp/SitePages/D365-Hub.aspx",
            target: "_blank",
          },
        },
        [_vm._v("here")]
      ),
      _vm._v(". "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }