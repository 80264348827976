













import { Component, Vue } from 'vue-property-decorator';
import Application from '@/models/Application';
import MappingList from '@/components/MappingList.vue';

@Component({
    components: {
        MappingList,
    },
})
export default class OrgWithApplication extends Vue {
    public orgId = '';

    public appName = '';

    public showMappingList = false;

    handleShowMappingList () {
        this.showMappingList = true;
    }

    get orgDetails () {
        return this.$store.getters.getOrgDetails(this.orgId);
    }

    get application () {
        return this.$store.state.applications.find(
            (app: Application) => app.name.toLowerCase() === this.appName,
        );
    }

    created () {
        this.orgId = this.$route.params.orgId;
        this.appName = this.$route.params.appName;
    }
}
