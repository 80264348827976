var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "associations" }, [
    _c(
      "div",
      { staticClass: "top" },
      [
        _c("Button", {
          directives: [{ name: "wave", rawName: "v-wave" }],
          attrs: {
            config: { label: "ABC Pensions Teams", color: "watermellon" },
          },
        }),
        _c(
          "div",
          { staticClass: "users-wrapper" },
          [
            _c("font-awesome-icon", { attrs: { icon: ["fad", "users"] } }),
            _c("div", { staticClass: "user-count" }, [_vm._v("25")]),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "bottom" }, [
      _c(
        "div",
        { staticClass: "overrides-selection" },
        [
          _c("div", { staticClass: "overrides-text" }, [
            _vm._v("User Type overrides"),
          ]),
          _c("Multiselect", {
            staticClass: "select",
            attrs: { showLabels: false, options: _vm.options },
            model: {
              value: _vm.selectedOption,
              callback: function ($$v) {
                _vm.selectedOption = $$v
              },
              expression: "selectedOption",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "overrides-selection" },
        [
          _c("div", { staticClass: "overrides-text" }, [
            _vm._v("User Type overrides"),
          ]),
          _c("Multiselect", {
            staticClass: "select",
            attrs: { showLabels: false, options: _vm.options },
            model: {
              value: _vm.selectedOption,
              callback: function ($$v) {
                _vm.selectedOption = $$v
              },
              expression: "selectedOption",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }