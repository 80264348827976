var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-result", on: { click: _vm.addOrganisation } },
    [
      _c("div", { staticClass: "search-research-name" }, [
        _vm._v(_vm._s(_vm.orgData.schemeName)),
      ]),
      _vm.logo
        ? _c("div", { staticClass: "img-wrapper" }, [
            _c("img", {
              staticClass: "logo",
              attrs: { src: "/svgs/microsoft-teams.svg" },
            }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }