import Vue from 'vue';
import VWave from 'v-wave';
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import Multiselect from 'vue-multiselect';
import PortalVue from 'portal-vue';
import VueSweetalert2 from 'vue-sweetalert2';
import Vuelidate from 'vuelidate';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import {
    FontAwesomeIcon,
    FontAwesomeLayers,
} from '@fortawesome/vue-fontawesome';

import {
    faSlash,
    faFilter,
    faCheckSquare,
    faTimes,
    faExclamation,
    faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons';

import { faPlus, faChevronRight } from '@fortawesome/pro-light-svg-icons';

import { faSquare, faCheck } from '@fortawesome/pro-regular-svg-icons';
import {
    faUserCircle,
    faUser,
    faUndo,
} from '@fortawesome/free-solid-svg-icons';

import {
    faTimesCircle,
    faUsers,
    faPencil,
    faUserShield,
    faSpinnerThird,
    faShieldAlt,
    faSearch,
    faBook,
    faUpload,
    faEdit,
    faUserPlus,
    faSave,
    faTrashAlt,
    faList,
    faUserFriends,
    faUsersClass,
    faSignOutAlt,
    faArrowSquareRight,
    faArrowDown,
    faHomeAlt,
    faUserMinus,
} from '@fortawesome/pro-duotone-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
import App from './App.vue';

import router from './router';

import store from './store';

// Vue.component("text-highlight", TextHighlight);
Vue.component('multiselect', Multiselect);

Vue.use(VWave);
Vue.directive('tooltip', VTooltip);
Vue.directive('close-popover', VClosePopover);
Vue.component('v-popover', VPopover);
Vue.use(VueSweetalert2);

Vue.use(PortalVue);
Vue.use(Vuelidate);

library.add(
    faTimesCircle,
    faUsers,
    faPencil,
    faUserShield,
    faSpinnerThird,
    faShieldAlt,
    faSearch,
    faBook,
    faUpload,
    faEdit,
    faUserPlus,
    faPlus,
    faSave,
    faTimes,
    faTrashAlt,
    faList,
    faSlash,
    faFilter,
    faCheckSquare,
    faSquare,
    faChevronRight,
    faUserFriends,
    faUsersClass,
    faSignOutAlt,
    faCheck,
    faArrowSquareRight,
    faArrowDown,
    faHomeAlt,
    faUserCircle,
    faUser,
    faUserMinus,
    faUndo,
    faCheck,
    faExclamation,
    faExclamationTriangle,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
