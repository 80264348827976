








































import { Component, Prop, Vue } from 'vue-property-decorator';

import Nav from '@/components/Nav.vue';

@Component({
    components: {
        Nav,
    },
})
export default class Header extends Vue {
    @Prop() private msg!: string;

    logout (): void {
        this.$store.dispatch('auth/logout');
    }

    get lcpUser (): any {
        return this.$store.state.lcpUser;
    }
}
