































import { Component, Prop, Vue } from 'vue-property-decorator';
import UserSearchResult from '@/components/UserSearchResult.vue';
import TextInput from '@/components/TextInput.vue';
import UserResult from '@/models/UserResult';

@Component({ components: { UserSearchResult, TextInput } })
export default class UserSearchWrapper extends Vue {
    @Prop() data!: UserResult[];

    @Prop() orgSearch!: boolean;

    @Prop() userSearch!: boolean;

    public loading!: boolean;

    public inputValue = '';

    get filteredByInputValue () {
        // when searching users
        if (this.userSearch && this.inputValue.length > 3) {
            return this.data.filter((elem: any) => Object.keys(elem).some((item) => elem[item].toLowerCase().includes(this.inputValue.toLowerCase()),
            ),
            );
        }
        return null;
    }

    get handleLoading () {
        return !(this.inputValue.length > 2);
    }
}
