












import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MappingList extends Vue {
    public value = 'ABC_Pension_org';

    public options = [
        'ABC_Pension_org',
        'ICI_Specialty_Chemicals',
        'Telefonica_Uk_Pension',
    ];
}
