var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "button", class: _vm.config.color }, [
    _vm._v(_vm._s(_vm.config.label)),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }