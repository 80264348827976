














import { Component, Prop, Vue } from 'vue-property-decorator';
import Application from '@/models/Application';

@Component
export default class ApplicationBubble extends Vue {
    @Prop() application!: Application;

    // FEEDBACK If using a standard type then declar it in the initial () also otherwise it doesnt know how to parse it
    @Prop(Boolean) small?: boolean;

    @Prop(Boolean) large?: boolean;

    @Prop(Boolean) chip?: boolean;

    @Prop(Boolean) selected!: boolean;
}
