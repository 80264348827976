






























import { Component, Prop, Vue } from 'vue-property-decorator';
import UserTableRow from '@/components/UserTableRow.vue';
import UserResult from '@/models/UserResult';
import Application from '@/models/Application';
import ApplicationBubble from '@/components/ApplicationBubble.vue';

@Component({ components: { UserTableRow, ApplicationBubble } })
export default class UserAccessAppTable extends Vue {
    @Prop() application!: Application;

    @Prop() query!: string;

    @Prop() users!: UserResult[];

    get applicationUsers () {
        const users = this.users.filter((elem: UserResult) => elem.applications.includes(this.application.id),
        );
        if (users.length === 0) {
            this.$emit('areNoResults', this.application.id);
        } else {
            this.$emit('areResults', this.application.id);
        }
        return users;
    }
}
