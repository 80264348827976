











import { Vue, Component, Prop } from 'vue-property-decorator';
import TeamMember from '@/models/TeamMember';

@Component({ components: {} })

export default class TeamMembersSidebarCard extends Vue {
    @Prop() member!: TeamMember;

    showAllTeams = false;

    showAllTeamsForMember (): void {
        this.showAllTeams = !this.showAllTeams;
    }
}

