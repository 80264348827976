













































import { Component, Vue } from 'vue-property-decorator';
import TextInput from '@/components/TextInput.vue';
import UserAccessApplication from '@/components/UserAccessApplication.vue';
import UserAccessAppTable from '@/components/UserAccessAppTable.vue';
import UserTableRow from '@/components/UserTableRow.vue';
import Application from '@/components/Application.vue';
import FilterPanelAlt from '@/components/FilterPanelAlt.vue';
import Org from '@/components/Org.vue';
import OrgType from '@/models/Org';
import UserResult from '@/models/UserResult';
import { Route } from 'vue-router';
import Applications from '@/models/Application';
import FilterOptions from '@/models/FilterOptions';

@Component({
    components: {
        TextInput,
        UserTableRow,
        UserAccessApplication,
        Application,
        Org,
        UserAccessAppTable,
        FilterPanelAlt,
    },
})
export default class UserAcessAlt extends Vue {
    public FilterPanel = false;

    public inputValue = '';

    public activeAppFilters: string[] = [];

    public activeOrgFilters: string[] = [];

    public activeNameFilters: string[] = [];

    public areNoResults = false;

    public emptyAppTables: string[] = [];

    public urlQueries: FilterOptions = {
        activeAppFilters: [],
        activeOrgFilters: [],
        activeNameFilters: [],
    };

    activateFilterPannel () {
        this.FilterPanel = !this.FilterPanel;
    }

    updateFilterOptions (filterOptions: FilterOptions) {
        this.activeAppFilters = filterOptions.activeAppFilters;
        this.activeOrgFilters = filterOptions.activeOrgFilters;
        this.activeNameFilters = filterOptions.activeNameFilters;
    }

    get allApplications () {
        return this.$store.state.applications;
    }

    get allOrgs () {
        return this.$store.state.orgs;
    }

    addEmptyAppTable (appId: string) {
        if (!this.emptyAppTables.includes(appId)) {
            this.emptyAppTables.push(appId);
        }
    }

    removeEmptyAppTable (appId: string) {
        if (this.emptyAppTables.includes(appId)) {
            const index = this.emptyAppTables.indexOf(appId);
            this.emptyAppTables.splice(index, 1);
        }
    }

    get filteredApplications () {
    // filter from array of strings
        if (this.activeAppFilters.length > 0) {
            const filteredApps = this.allApplications.filter((item: Applications) => this.activeAppFilters.includes(item.id),
            );
            return filteredApps;
        }
        return this.allApplications;
    }

    get filteredOrgDetails () {
        const orgDetails = this.activeOrgFilters.map((elem) => this.$store.getters.getOrgDetails(elem),
        );
        return orgDetails.map((elem: OrgType) => elem.orgName);
    }

    filterUsersByApp (arr: UserResult[]) {
        return arr.filter(
            (item: UserResult) => !item.applications.some((elem) => this.activeAppFilters.includes(elem)),
        );
    }

    filterUsersByOrg (arr: UserResult[]) {
        if (this.activeOrgFilters.length > 0) {
            return arr.filter((item: UserResult) => item.org.some((elem) => this.filteredOrgDetails.includes(elem)),
            );
        }
        return arr;
    }

    filterUsersByName (arr: UserResult[]) {
        return arr.filter((elem: UserResult) => elem.name.toLowerCase().includes(this.inputValue.toLowerCase()),
        );
    }

    // only works with exact string
    filterUsersByNameArr (arr: UserResult[]) {
        if (this.activeNameFilters.length > 0) {
            return arr.filter((item: UserResult) => this.activeNameFilters.some((elem) => elem.toLowerCase() === item.name.toLowerCase(),
            ),
            );
        }
        return arr;
    }

    get allUsers () {
        return this.$store.state.abcUsersAdmin;
    }

    get combined () {
        this.areNoResults = false;
        let filteredUsers = [...this.allUsers];
        // if (this.activeAppFilters.length > 0) {
        //   filteredUsers = this.filterUsersByApp(filteredUsers);
        // }
        if (this.filteredOrgDetails.length > 0) {
            filteredUsers = this.filterUsersByOrg(filteredUsers);
        }
        if (this.inputValue.length > 0) {
            filteredUsers = this.filterUsersByName(filteredUsers);
        }
        if (this.activeNameFilters.length > 0) {
            filteredUsers = this.filterUsersByNameArr(filteredUsers);
        }
        // show 'refine search'
        if (filteredUsers.length === 0) {
            this.areNoResults = true;
        }
        return filteredUsers;
    }

    created () {
        const urlQuery = (this.$route as Route).query;
        if (urlQuery.filterByName) {
            const urlNamesQuery = (urlQuery.filterByName as string).split(',');
            // REMOVEED BECAUSE SEARCH BY NAME REMOVED
            // this.inputValue = urlQuery.filterByName;
            this.urlQueries.activeNameFilters = [...urlNamesQuery];
        }
        if (urlQuery.filterByOrg) {
            // pass scemeId from side bar
            // remove that orgId from all orgIds
            const contraryOrgs = this.allOrgs.filter(
                (elem: OrgType) => elem.id === urlQuery.filterByOrg,
            );
            this.urlQueries.activeOrgFilters = contraryOrgs.map(
                (elem: OrgType) => elem.id,
            );
        }
        if (urlQuery.filterByApp) {
            const urlAppsQuery = (urlQuery.filterByApp as string).split(',');
            const contraryApps = this.allApplications.filter((elem: Applications) => urlAppsQuery.includes(elem.id),
            );
            this.urlQueries.activeAppFilters = contraryApps.map(
                (elem: Applications) => elem.id,
            );
        }
    }
}
