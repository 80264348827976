var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "org-details-wrapper" },
    [
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.orgLoaded
            ? _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "org-details-content" }, [
                  _c("div", { staticClass: "org-details--heading" }, [
                    _c("div", { staticClass: "heading-top" }, [
                      _vm.editingName
                        ? _c("div", { staticClass: "edit-org-name" }, [
                            _c(
                              "div",
                              { staticClass: "org-name" },
                              [
                                _c("TextInput", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: "Enter organisation name",
                                      expression: "'Enter organisation name'",
                                    },
                                  ],
                                  attrs: {
                                    placeholder: "Enter new organisation name",
                                  },
                                  model: {
                                    value: _vm.orgName,
                                    callback: function ($$v) {
                                      _vm.orgName = $$v
                                    },
                                    expression: "orgName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "save-button" },
                              [
                                _c(
                                  "base-button",
                                  {
                                    staticClass:
                                      "btn-gradient condensed inline right",
                                    attrs: {
                                      disabled: _vm.orgName.length < 1,
                                      handleClick: _vm.saveName,
                                    },
                                  },
                                  [_vm._v(" Save ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "save-button" },
                              [
                                _c(
                                  "base-button",
                                  {
                                    staticClass:
                                      "btn-gradient condensed inline right",
                                    attrs: {
                                      handleClick: _vm.cancelNameUpdate,
                                    },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _c("div", { staticClass: "card-inner-heading" }, [
                            _c("h1", [
                              _vm._v(
                                " " + _vm._s(_vm.orgDetails.org.name) + " "
                              ),
                            ]),
                            _vm.isInactive
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: "No Apps associated with Org",
                                        expression:
                                          "'No Apps associated with Org'",
                                      },
                                    ],
                                    staticClass: "inactive-label",
                                  },
                                  [_vm._v("Inactive")]
                                )
                              : _vm._e(),
                            !_vm.orgDetails.d365Entity
                              ? _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "edit-link-underline",
                                      on: {
                                        click: function ($event) {
                                          return _vm.showNameTextbox()
                                        },
                                      },
                                    },
                                    [_vm._v("edit name")]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "org-details--body" }, [
                  _c(
                    "div",
                    { staticClass: "teams-card-holder" },
                    [
                      _c("div", { staticClass: "teams-card-inner" }, [
                        _c("div", { staticClass: "card-inner-heading" }, [
                          _c("h2", [_vm._v("D365 association")]),
                          _c(
                            "span",
                            {
                              staticClass: "edit-link-underline",
                              on: {
                                click: function ($event) {
                                  return _vm.showEditSidebar("editingD365")
                                },
                              },
                            },
                            [_vm._v("edit d365 entity")]
                          ),
                        ]),
                        _vm.orgDetails.d365Entity
                          ? _c("div", [
                              _c("div", { staticClass: "card" }, [
                                _c(
                                  "div",
                                  { staticClass: "card-body d365card" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.orgDetails.d365Entity.name)
                                      ),
                                    ]),
                                    _c(
                                      "a",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: "Check on Dynamics 365",
                                            expression:
                                              "'Check on Dynamics 365'",
                                          },
                                        ],
                                        staticClass: "btn-dynamics",
                                        attrs: {
                                          target: "_blank",
                                          href: _vm.linkAccountOrScheme,
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "dynamics-img",
                                          attrs: {
                                            src: "/svgs/Dynamics365_scalable.svg",
                                            alt: "dynamics 365 image",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _c("div", { staticClass: "no-items" }, [
                              _vm._v(" No D365 associations "),
                            ]),
                      ]),
                      _c("div", { staticClass: "teams-card-inner" }, [
                        _c("div", { staticClass: "card-inner-heading" }, [
                          _c(
                            "h2",
                            { staticClass: "card-inner-heading--left" },
                            [
                              _vm._v("Teams channels "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: "View Team Members",
                                      expression: "'View Team Members'",
                                    },
                                  ],
                                  staticClass: "member-icon",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showEditSidebar(
                                        "viewTeamMembers"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "icon",
                                    attrs: { icon: ["fad", "user-friends"] },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "edit-link-underline",
                              on: {
                                click: function ($event) {
                                  return _vm.showEditSidebar("editingTeams")
                                },
                              },
                            },
                            [_vm._v("add / edit channels")]
                          ),
                        ]),
                        _vm.orgDetails.teams.length > 0
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.orgDetails.teams,
                                function (team, index) {
                                  return _c("TeamsCard", {
                                    key: index,
                                    attrs: { team: team },
                                  })
                                }
                              ),
                              1
                            )
                          : _c("div", { staticClass: "no-items" }, [
                              _vm._v(" No Teams associations "),
                              _c("div", { staticClass: "teams-img" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/microsoft-teams.svg"),
                                  },
                                }),
                              ]),
                            ]),
                      ]),
                      _vm.portalInView === "viewTeamMembers"
                        ? _c(
                            "Portal",
                            { attrs: { to: "sidebar" } },
                            [
                              _c("TeamMembersSidebar", {
                                ref: "viewTeamMembersSidebar",
                                attrs: {
                                  orgId: _vm.orgId,
                                  orgDetails: _vm.orgDetails,
                                },
                                on: {
                                  requestLatestData: _vm.requestLatestData,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.portalInView === "editingTeams"
                        ? _c(
                            "Portal",
                            { attrs: { to: "sidebar" } },
                            [
                              _c("EditTeamsSidebar", {
                                ref: "editTeamsSidebar",
                                attrs: {
                                  orgTeamList: _vm.orgDetails.teams,
                                  orgId: _vm.orgId,
                                },
                                on: {
                                  requestLatestData: _vm.requestLatestData,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.portalInView === "editingApps"
                        ? _c(
                            "Portal",
                            { attrs: { to: "sidebar" } },
                            [
                              _c("EditAppsSidebar", {
                                ref: "editAppsSidebar",
                                attrs: {
                                  orgAppList: _vm.applicationsById,
                                  orgId: _vm.orgId,
                                },
                                on: {
                                  requestLatestData: _vm.requestLatestData,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.portalInView === "editingD365"
                        ? _c(
                            "Portal",
                            { attrs: { to: "sidebar" } },
                            [
                              _c("EditD365Sidebar", {
                                ref: "editD365Sidebar",
                                attrs: {
                                  orgId: _vm.orgId,
                                  orgName: _vm.orgName,
                                },
                                on: {
                                  requestLatestData: _vm.requestLatestData,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.portalInView === "externalUsersMatrix"
                        ? _c(
                            "Portal",
                            { attrs: { to: "sidebar" } },
                            [
                              _c("EditExternalUsersMatrixSidebar", {
                                ref: "editExternalUsersMatrixSidebar",
                                attrs: {
                                  orgAppList: _vm.applicationsById,
                                  orgId: _vm.orgId,
                                  allRolesByApp: _vm.orgDetails.roles,
                                },
                                on: {
                                  requestLatestData: _vm.requestLatestData,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "applications-holder" }, [
                    _c("div", { staticClass: "applications-holder--heading" }, [
                      _c("div", { staticClass: "card-inner-heading" }, [
                        _c(
                          "div",
                          { staticClass: "card-inner-heading--left" },
                          [
                            _c("h2", [_vm._v("App list")]),
                            _vm.showUserBubble
                              ? _c("UserBubble", {
                                  staticClass: "external-users-bubble",
                                  on: {
                                    userClick: function ($event) {
                                      return _vm.showEditSidebar(
                                        "externalUsersMatrix"
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "edit-link-underline",
                          on: {
                            click: function ($event) {
                              return _vm.showEditSidebar("editingApps")
                            },
                          },
                        },
                        [_vm._v("add / edit apps")]
                      ),
                    ]),
                    _vm.applicationsById.length > 0
                      ? _c(
                          "div",
                          [
                            _vm.applicationsById
                              ? _c("ApplicationGroup", {
                                  attrs: {
                                    allApplications: _vm.applicationsById,
                                    orgId: _vm.orgId,
                                    editAppList: _vm.editAppList,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c("div", { staticClass: "no-items" }, [
                          _vm._v(" No Applications "),
                        ]),
                  ]),
                ]),
              ])
            : _c("LoaderLogo", { attrs: { condensed: "" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }