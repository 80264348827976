var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "teams-result-card" }, [
      _vm._m(0),
      _c("div", { staticClass: "teams-content" }, [
        _c("span", { staticClass: "title" }, [
          _vm._v(
            " " +
              _vm._s(_vm.team.name) +
              " - " +
              _vm._s(_vm.team.channelName) +
              " "
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "icon-holder",
          on: {
            click: function ($event) {
              return _vm.handleRemoveAssociation()
            },
          },
        },
        [
          _vm.notLastCompany
            ? _c("font-awesome-icon", {
                staticClass: "icon",
                attrs: { icon: ["fad", "trash-alt"] },
              })
            : _c("font-awesome-icon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Unable to delete: One or more Teams must exist.",
                    expression:
                      "'Unable to delete: One or more Teams must exist.'",
                  },
                ],
                staticClass: "icon icon-inactive",
                attrs: { icon: ["fad", "trash-alt"] },
              }),
        ],
        1
      ),
    ]),
    _vm.showTeamsUpdateErrorMessages
      ? _c(
          "div",
          {
            class: {
              "success-message": _vm.isSuccessMessage,
              "error-message": _vm.isErrorMessage,
            },
          },
          [_vm._v(" " + _vm._s(_vm.errorMessage))]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "teams-logo" }, [
      _c("img", { attrs: { src: "/svgs/microsoft-teams.svg" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }