













import { Component, Vue, Prop } from 'vue-property-decorator';
import PermissionTag from '@/components/PermissionTag.vue';
import AppBubble from '@/components/AppBubble.vue';
import ApplicationBubble from '@/components/ApplicationBubble.vue';

@Component({ components: { PermissionTag, AppBubble, ApplicationBubble } })
export default class UserApps extends Vue {
    @Prop() userType!: string;

    @Prop() userName!: string;

    @Prop() apps!: string[];

    get userIcon () {
        switch (this.userType) {
            case 'User':
                return 'users';
            case 'Org Admin':
                return 'user-shield';
            default:
                return this.userType;
        }
    }
}
