




import { Component, Vue, Prop } from 'vue-property-decorator';
import ButtonConfig from '@/models/ButtonConfig';

@Component
export default class Button extends Vue {
    @Prop() config!: ButtonConfig;
}
