var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filter-side-panel-wrapper" }, [
    _c("div", { staticClass: "filter-side-panel" }, [
      _c(
        "div",
        { staticClass: "filter-type" },
        [
          _c("TextFilterWithDropdown", {
            ref: "userFilter",
            attrs: {
              inputIcon: "user-shield",
              data: _vm.unselectedUsers,
              placeholder: "Filter Users",
              searchBy: ["name", "email"],
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var filteredByInputValue = ref.filteredByInputValue
                  return _vm._l(filteredByInputValue, function (data, i) {
                    return _c("UserSearchResult", {
                      key: i,
                      attrs: { data: data },
                      on: {
                        "handle-click": _vm.updateUserNameFiltersFromSearch,
                      },
                    })
                  })
                },
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "chip-container" },
            _vm._l(_vm.activeNameFilters, function (user, j) {
              return _c("Chip", {
                key: "scheme-" + j,
                attrs: { chipLabel: user, chipId: user },
                on: { orgClickHandler: _vm.updateUserNameFilters },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filter-type underline" },
        [
          _c("TextFilterWithDropdown", {
            ref: "orgFilter",
            attrs: {
              inputIcon: "shield-alt",
              data: _vm.unselectedOrgFilters,
              searchBy: ["orgName"],
              placeholder: "Filter Orgs",
              filterBgColorDefault: true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var filteredByInputValue = ref.filteredByInputValue
                  return _vm._l(filteredByInputValue, function (data, i) {
                    return _c("OrgSearchResult", {
                      key: i,
                      attrs: { data: data },
                      on: {
                        "add-filter-id": function ($event) {
                          return _vm.updateOrgFilters(data.id)
                        },
                      },
                    })
                  })
                },
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "chip-container" },
            _vm._l(_vm.selectedOrgFilters, function (org, j) {
              return _c("Chip", {
                key: j,
                attrs: {
                  chipData: org,
                  chipLabel: org.orgName,
                  chipId: org.id,
                },
                on: { orgClickHandler: _vm.updateOrgFilters },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filter-type" },
        _vm._l(_vm.allApplications, function (app, i) {
          return _c("Checkbox", {
            key: i,
            attrs: { checkboxData: app, activeCheckbox: _vm.activeAppFilters },
            on: { "select-handler": _vm.updateAppFilters },
          })
        }),
        1
      ),
      _c("div", { staticClass: "filter-type" }, [
        _vm._v(
          " " +
            _vm._s(_vm.numberOfPeople) +
            " " +
            _vm._s(_vm.numberOfPeople == 1 ? "User" : "Users") +
            " found "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }